import React from 'react'
import { observer } from 'mobx-react'
import Canvas from 'components/Canvas/Canvas'

const MarkupCamera = observer(props => {
  const { video, selectedOI, onClick, points } = props

  const handleDrawEnd = async (shape, callback) => {
    if (selectedOI && video.frame) {
      if (shape.w === 0 || shape.h === 0) {
        await video.frame.removeLocation(selectedOI, callback)
      } else {
        await video.frame.newLocation(shape, selectedOI, callback)
      }
    }
  }

  return (
    <Canvas
      selectedShapeId={selectedOI ? selectedOI.id : undefined}
      selectedColor={selectedOI ? selectedOI.color : undefined}
      locations={video.frame?.locations}
      bg={video.frame?.img_file}
      points={points}
      frame={video.frame}
      onClick={onClick}
      onDrawEnd={handleDrawEnd}
    />
  )
})

export default MarkupCamera
