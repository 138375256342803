import React, { useContext, useEffect } from 'react'
import { reaction, when } from 'mobx'
import { useLocalStore } from 'mobx-react'
import { onAction } from 'mobx-state-tree'
import { RouterModel, syncHistoryWithStore } from 'mst-react-router'
import { createBrowserHistory } from 'history'
import { RootStore } from 'store/root'

const createRouterStore = () => {
  const routerModel = RouterModel.create()
  const history = syncHistoryWithStore(createBrowserHistory(), routerModel)
  return [routerModel, history]
}
const [routerStore, _history] = createRouterStore()
export const history = _history

export function createStore() {
  return RootStore.create({
    router: routerStore,
    session: {
      authenticated: false,
      progress: 'idle',
      error: '',
      pk: 0,
      userName: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    projects: {
      total: 0,
      progress: 'idle',
      current: null,
      list: [],
      error: '',
    },
    shared: {
      currentFrame: 0,
      oi: [],
      aoi: [],
      oiProgress: 'idle',
      aoiProgress: 'idle',
      selectedOI: null,
      progress: 'idle',
      autoNextFrame: false,
    },
    projectScheme: {
      showAoi: false,
      progress: 'idle',
      segments: [],
      from: 0,
      to: 0,
      _fromDate: null,
      _toDate: null,
      _frame: {},
      _currentFrameId: null,
      currentPointIndex: null,
      reports: [],
      reportCreatingProgress: 'idle',
      reportsFetchProgress: 'idle',
      aoiEditState: 'none',
      aoiDraftCoords: null,
    },
  })
}

const storeContext = React.createContext({
  store: null,
})

export const StoreProvider = ({ children }) => {
  const store = useLocalStore(createStore)
  useEffect(() => {
    onAction(store, action => {
      // console.log('main store action:', action)
    })
    when(
      () => store.session.authenticated,
      () => {
        store.projects.route(routerStore.location)
        store.shared.route(routerStore.location)
        store.projectScheme.route(routerStore.location)
      }
    )
    reaction(
      () => routerStore.location,
      location => {
        store.projects.route(routerStore.location)
        store.shared.route(routerStore.location)
        store.projectScheme.route(routerStore.location)
      }
    )
  }, [store])
  return (
    <storeContext.Provider
      value={{
        store,
      }}
    >
      {children}
    </storeContext.Provider>
  )
}

export const useStore = () => {
  const store = useContext(storeContext)
  if (!store.store) {
    throw new Error('Store not provided!')
  }
  return store.store
}

export default useStore
