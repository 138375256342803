import React from 'react'
import { Spinner } from '@blueprintjs/core'
import styles from './Spinner.module.pcss'

const SpinnerComponent = ({ size = 100 }) => {
  return (
    <div className={styles.root}>
      <Spinner size={size} />
    </div>
  )
}

export default SpinnerComponent
