import React from 'react'

function useKeyCodePress(targetKey, downCallback, upCallback) {
  const [keyPressed, setKeyPressed] = React.useState(false)

  function downHandler({ keyCode }) {
    if (keyCode === targetKey) {
      setKeyPressed(true)
      if (downCallback) {
        downCallback()
      }
    }
  }

  const upHandler = ({ keyCode }) => {
    if (keyCode === targetKey) {
      setKeyPressed(false)
      if (upCallback) {
        upCallback()
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, []) //eslint-disable-line

  return keyPressed
}

export default useKeyCodePress
