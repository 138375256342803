import React from 'react'
import { observer } from 'mobx-react'
import useStore from 'store'
import { Button, FormGroup, InputGroup, Card, Elevation, Callout, Label } from '@blueprintjs/core'
import styles from './Auth.module.pcss'
import pastaURI from './pasta.png'
import { Redirect } from 'react-router-dom'

const AuthPage = observer(() => {
  const store = useStore()

  const onSubmit = e => {
    e.preventDefault()
    const [emailInput, passwordInput] = e.target.elements
    store.session.logIn(emailInput.value, passwordInput.value)
  }

  if (store.session.authenticated) {
    return <Redirect to={'/'} />
  }

  return (
    <div className={styles.root}>
      <div className={styles.cardWrapper}>
        <Card elevation={Elevation.THREE} className={styles.card}>
          <img className={styles.icon} src={pastaURI} />
          {store.session.error !== '' ? <Callout intent={'danger'}>{store.session.error}</Callout> : null}
          <form onSubmit={onSubmit} className={styles.form}>
            <FormGroup className={styles.cardInner}>
              <Label htmlFor={'username'}>Имя пользователя</Label>
              <InputGroup id={'username'} placeholder={'username'} leftIcon={'user'} />
              <br />
              <Label htmlFor={'password'}>Пароль</Label>
              <InputGroup id={'password'} placeholder={'password'} type={'password'} leftIcon={'lock'} />
              <br />
              <Button type={'submit'} text={'Вход'} rightIcon={'arrow-right'} />
            </FormGroup>
          </form>
        </Card>
      </div>
    </div>
  )
})

export default AuthPage
