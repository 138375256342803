import React from 'react'
import styles from './EmptyFrame.module.pcss'
import emptyFrameImage from './empty_frame.jpg'

const EmptyFrame = () => {
  return (
    <div className={styles.wrapper}>
     <img src={emptyFrameImage} className={styles.img} />
    </div>
  )
}

export default EmptyFrame
