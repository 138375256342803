import React, { useState, useRef, useEffect, useCallback } from 'react'
import useStore from 'store'
import styles from './ProjectScheme.module.pcss'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { GeoJSON, ImageOverlay, Map, Marker, Polygon } from 'react-leaflet'
import Control from 'react-leaflet-control'
import L from 'leaflet'
import 'leaflet.path.drag'
import 'leaflet-editable'
import 'leaflet/dist/leaflet.css'
import { OISelect } from 'components/OISelect/OISelect'
import {
  Button,
  Checkbox,
  Divider,
  InputGroup,
  MenuItem,
  Overlay,
  RangeSlider,
  Slider,
  Dialog,
  Classes,
  Intent,
  Card,
  Icon,
} from '@blueprintjs/core'
import SpinnerComponent from 'components/Spinner/Spinner'
import { toFull } from 'helpers/date'
import { Select } from '@blueprintjs/select'
import Canvas from 'components/Canvas/Canvas'
import { invertColor } from '../../helpers/color'
import { Route, Switch } from 'react-router'
import { SCHEME, STATS } from '../routes'
import { FACTORY_X, FACTORY_Y } from '../../constants/bounds'
import { StatsContent } from './Stats'
import debounce from 'lodash/debounce'

const svg = color => `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="40" height="40">
    <path fill="${color}" d="M466.29 15.11c-3.68 3.68-5.11 7.15-5.11 13.28 0 6.13-.82 8.17-3.27 8.17-1.63 0-11.85 2.86-22.47 6.33l-19.2 6.54v42.29c0 39.84-.2 42.7-4.29 47.81-5.52 6.95-17.57 7.56-23.49 1.02-3.47-3.68-4.09-8.58-4.9-38l-1.02-33.71-8.17 6.13c-46.58 33.71-81.72 94.39-86.21 148.52l-1.23 14.3-14.3 13.48c-13.69 13.08-14.3 13.89-13.08 21.04 4.7 29.01 58.22 54.75 136.27 65.58 31.67 4.5 135.04 6.33 171.81 3.27 79.06-6.74 135.04-23.9 160.99-49.03 17.57-17.16 16.75-25.54-3.68-41.06l-14.1-10.83-1.23-13.89c-5.72-56.39-36.36-112.77-80.49-147.5l-12.46-9.6-.61 33.5c-.61 29.62-1.02 33.91-4.5 37.79-5.92 6.54-17.98 5.92-23.49-1.02-4.09-5.11-4.29-7.97-4.29-47.19V50.65l-9.4-3.88c-5.11-2.25-15.12-5.72-22.47-7.56l-13.08-3.47v-7.97c0-5.52-1.43-9.19-5.11-12.67-4.7-4.9-5.92-5.11-33.71-5.11-27.78.01-29.01.21-33.71 5.12zM298.15 354.65c-15.53 22.27-12.26 74.98 7.35 114 6.74 13.48 19.82 32.69 29.21 42.9 4.7 5.11 9.4 13.48 12.26 21.45 26.97 78.86 95.2 131.57 162.62 125.85C571.9 653.74 629.1 603.28 653.01 533c2.86-7.97 7.56-16.34 12.26-21.45 8.99-9.81 22.27-29.21 28.81-41.88 17.57-34.53 22.88-84.58 11.24-108.89-5.72-12.26-7.35-12.87-24.11-7.97-64.15 18.8-161.39 26.97-242.71 20.84-38.82-3.06-90.5-11.44-114.2-18.39-11.24-3.47-20.63-6.13-21.25-6.13-.61 0-2.65 2.46-4.9 5.52z"/>
    <path fill="${color}" d="M332.07 605.73l-17.98 7.36v191.63h371.82V613.09l-17.77-7.15-17.98-7.36-5.11 6.54c-20.84 26.76-47.19 48.42-74.57 61.7-23.29 11.24-37.79 15.12-62.31 16.75-54.55 3.27-108.07-23.7-151.38-76.41l-6.95-8.58-17.77 7.15zM228.28 649.25c-60.68 27.17-91.32 50.67-116.86 89.28-30.23 45.76-52.7 117.47-52.7 168.54 0 20.02.61 20.84 17.37 27.79 12.67 5.31 44.54 15.52 62.31 20.02 7.36 1.84 14.91 3.68 16.75 4.29 3.06.82 3.47-3.47 4.09-50.67l.61-51.89 6.74-13.49c12.26-25.13 31.46-36.37 64.15-38l20.02-1.02v-81.31c0-63.95-.61-81.51-2.45-81.51-1.44 0-10.42 3.68-20.03 7.97zM749.24 722.59v81.72l24.11.61c22.27.82 24.93 1.23 37.59 7.56 14.91 7.36 25.13 17.57 32.69 32.89 4.49 8.99 4.7 12.05 5.31 60.88.41 28.4 1.02 51.69 1.23 51.69 3.27 0 49.24-14.1 64.35-19.61 26.76-10.01 26.76-10.01 26.76-31.26 0-42.29-15.53-99.7-39.43-146.28-16.96-32.89-44.33-63.33-73.55-82.33-14.91-9.6-64.15-33.91-73.55-36.16l-5.52-1.43v81.72zM222.15 919.94v51.69l25.13 3.27c147.91 19.61 348.74 20.23 496.85 1.23 17.98-2.25 34.73-4.5 37.39-5.31l4.5-1.02V868.06H222.15v51.88z"/>
</svg>
`
const icons = {}

const FrameControls = observer(() => {
  const store = useStore()
  const [playbackSpeed, setPlaybackSpeed] = React.useState(1)
  const [isPlaying, setPlaying] = React.useState(false)

  useEffect(() => {
    if (!isPlaying) {
      return
    }
    const timeout = 3000 / playbackSpeed
    let tId
    function doStuff() {
      if (store.projectScheme.hasNextPoint) {
        store.projectScheme.nextPoint()
        tId = setTimeout(doStuff, timeout)
      } else {
        setPlaying(false)
      }
    }
    doStuff()
    return () => {
      clearTimeout(tId)
    }
  }, [isPlaying, playbackSpeed, store.projectScheme, store.projectScheme.hasNextPoint])
  const [, point] = store.projectScheme.frame || []
  return (
    <div className={styles.frameControlsContainer}>
      <Button
        icon={'arrow-left'}
        disabled={!store.projectScheme.hasPrevPoint}
        onClick={store.projectScheme.prevPoint}
      />
      <InputGroup disabled={true} value={point?.time ? toFull(point.time) : ''} />
      <Button
        icon={'arrow-right'}
        disabled={!store.projectScheme.hasNextPoint}
        onClick={store.projectScheme.nextPoint}
      />
      <Divider />
      <Select
        items={[1, 2, 3, 4, 5]}
        onItemSelect={v => setPlaybackSpeed(v)}
        filterable={false}
        popoverProps={{ minimal: true }}
        itemRenderer={(v, { handleClick, modifiers, query }) => {
          if (!modifiers.matchesPredicate) {
            return null
          }
          return (
            <MenuItem active={modifiers.active} disabled={modifiers.disabled} key={v} onClick={handleClick} text={v} />
          )
        }}
      >
        <Button>{playbackSpeed}</Button>
      </Select>
      <Divider />
      <Button
        disabled={!store.projectScheme.hasNextPoint}
        icon={isPlaying ? 'pause' : 'play'}
        onClick={() => {
          setPlaying(!isPlaying)
        }}
      />
    </div>
  )
})

const AOIEditDialog = observer(() => {
  const store = useStore()
  const aoi = store.projectScheme.selectedAOI || {}
  const [name, setName] = useState(aoi.name)
  const [color, setColor] = useState(aoi.color)
  const handleChangeName = e => {
    setName(e.target.value)
  }

  useEffect(() => {
    setName(aoi.name)
    setColor(aoi.color)
  }, [aoi.name, aoi.color])

  const handleCancel = useCallback(() => {
    store.projectScheme.setAOIEditState('none')
  }, [store.projectScheme])
  const handleRemove = useCallback(() => {
    store.projectScheme.removeCurrentAOI(aoi.id)
    store.projectScheme.setAOIEditState('none')
  }, [aoi.id, store.projectScheme])
  const handleSave = useCallback(() => {
    store.projectScheme.updateCurrentAOI(name, color)
    store.projectScheme.setAOIEditState('none')
  }, [color, name, store.projectScheme])

  return (
    <Dialog
      title={`Редактирование ${aoi.name}, id: ${aoi.id}`}
      isOpen={store.projectScheme.aoiEditState === 'edit'}
      onClose={handleCancel}
    >
      <div className={Classes.DIALOG_BODY}>
        <InputGroup label={'Имя области'} value={name} onChange={handleChangeName} />
        <br />
        <input type="color" value={color} onChange={e => setColor(e.target.value)} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleCancel} intent={Intent.NONE}>
            Отмена
          </Button>
          <Button onClick={handleRemove} intent={Intent.DANGER}>
            Удалить
          </Button>
          <Button
            onClick={handleSave}
            intent={Intent.SUCCESS}
            disabled={!name || (name === aoi.name && color === aoi.color)}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </Dialog>
  )
})

const AOIAddDialog = observer(() => {
  const store = useStore()
  const [name, setName] = useState('')
  const [color, setColor] = useState('#5075e3')
  const handleClose = useCallback(() => {
    store.projectScheme.setAOIEditState('none')
  }, [store.projectScheme])
  const handleSave = useCallback(() => {
    store.projectScheme.createAOI(name, color)
  }, [color, name, store.projectScheme])

  return (
    <Dialog
      title={`Добавление новой области интереса`}
      isOpen={store.projectScheme.aoiEditState === 'add'}
      onClose={handleClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <InputGroup label={'Имя области'} value={name} onChange={e => setName(e.target.value)} />
        <br />
        <input type="color" value={color} onChange={e => setColor(e.target.value)} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent={Intent.NONE} onClick={handleClose}>
            Отмена
          </Button>
          <Button intent={Intent.SUCCESS} onClick={handleSave} disabled={!name}>
            Сохранить
          </Button>
        </div>
      </div>
    </Dialog>
  )
})

const DateRangePicker = ({ min, max, from, to, onToChange, onFromChange, onDateChange }) => {
  const minU = min.getTime()
  const maxU = max.getTime()
  const fromU = from.getTime()
  const toU = to.getTime()
  return (
    <div className={styles.rangePickerRoot}>
      <div className={styles.rangeBlock}>
        <RangeSlider
          value={[fromU, toU]}
          min={minU}
          max={maxU}
          stepSize={3000}
          labelStepSize={(maxU - minU) / 5}
          onChange={([from, to]) => onDateChange([new Date(from), new Date(to)])}
          labelRenderer={v => {
            const date = new Date(v)
            if (isNaN(date.getTime())) {
              return v
            }
            return toFull(date)
          }}
        />
      </div>
    </div>
  )
}

const useEditablePoly = ({ onCreated, onCancel, imageOverlayFactoryY = FACTORY_Y } = {}) => {
  const ref = useRef()

  const clear = () => {
    if (ref.current) {
    }
  }

  const startEdit = () => {
    ref.current.leafletElement.editTools.startPolygon()
  }
  const stopEdit = () => {
    ref.current.leafletElement.editTools.stopDrawing()
  }

  const handleEscKeyup = e => {
    if (e.originalEvent.keyCode === 27) {
      onCancel()
    }
  }

  const handleCreated = useCallback(
    e => {
      onCreated(
        e.vertex.latlngs.map(({ lat, lng }) => {
          return [lng, Math.abs(lat - imageOverlayFactoryY)]
        })
      )
      e.layer.remove()
    },
    [onCreated, imageOverlayFactoryY]
  )

  useEffect(() => {
    if (ref.current) {
      ref.current.leafletElement.editTools.on('editable:drawing:commit', handleCreated)
      ref.current.leafletElement.on('keyup', handleEscKeyup)
    }
    return () => {
      if (ref.current) {
        ref.current.leafletElement.editTools.off('editable:drawing:commit', handleCreated)
        ref.current.leafletElement.off('keyup', handleEscKeyup)
      }
    }
  }, [handleCreated, handleEscKeyup, onCancel])

  return [ref, startEdit, stopEdit, clear]
}

const Poly = ({ positions, color, id, onUpdate, onSelect, selected, onEdit, imageOverlayFactoryY }) => {
  const ref = useRef()
  const [coords] = useState(positions)
  const handleChange = useCallback(
    debounce(e => {
      onUpdate(
        id,
        e.target.getLatLngs()[0].map(({ lat, lng }) => {
          return [lng, Math.abs(lat - imageOverlayFactoryY)]
        })
      )
    }, 100),
    [id, onUpdate, imageOverlayFactoryY]
  )

  const handleClick = useCallback(() => {
    onSelect(id)
  }, [id, onSelect])

  const handleDblClick = useCallback(() => {
    onEdit(id)
  }, [id, onEdit])

  useEffect(() => {
    if (ref.current) {
      if (selected && !ref.current.leafletElement.editEnabled()) {
        ref.current.leafletElement.enableEdit()
      } else if (ref.current.leafletElement.editEnabled()) {
        ref.current.leafletElement.disableEdit()
      }
    }
  }, [selected])

  useEffect(() => {
    if (ref.current) {
      ref.current.leafletElement.on('editable:editing', handleChange)
      ref.current.leafletElement.on('editable:dragend', handleChange)
      ref.current.leafletElement.on('click', handleClick)
      ref.current.leafletElement.on('dblclick', L.DomEvent.stop).on('dblclick', handleDblClick)
    }
    return () => {
      if (ref.current) {
        ref.current.leafletElement.off('editable:editing', handleChange)
        ref.current.leafletElement.off('editable:dragend', handleChange)
        ref.current.leafletElement.off('click', handleClick)
        ref.current.leafletElement.off('dblclick', L.DomEvent.stop).off('dblclick', handleDblClick)
      }
    }
  }, [handleChange, handleClick, handleDblClick])
  return <Polygon ref={ref} positions={coords} color={color} fillOpacity={selected ? '0.5' : '0.2'} />
}

const FrameViewer = observer(({ toggleFrame, frameOpened }) => {
  const store = useStore()
  const [frame] = store.projectScheme.frame || []
  const currentOI = store.shared.selectedOI
  const [bg, setBg] = useState(frame?.img_file)
  const location = frame && currentOI ? frame.locations.filter(l => l.oi.id === currentOI.id) : null
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    let tag
    const loadHandler = () => {
      setBg(frame.img_file)
      setLoading(false)
    }
    if (frame) {
      tag = new Image()
      tag.src = frame.img_file
      tag.addEventListener('load', loadHandler)
      setLoading(true)
    }
    return () => {
      if (tag) {
        tag.removeEventListener('load', loadHandler)
      }
    }
  }, [frame])

  useEffect(() => {
    const handleKeyUp = e => {
      switch (e.keyCode) {
        case 37:
          store.projectScheme.prevPoint()
          break
        case 39:
          store.projectScheme.nextPoint()
          break
      }
    }

    if (frameOpened) {
      document.addEventListener('keyup', handleKeyUp)
    }
    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [frameOpened, store.projectScheme])

  return (
    <Card className={styles.frameViewerContainer}>
      <div className={styles.frameViewerSpinner} data-loading={loading}>
        <SpinnerComponent />
      </div>
      <div className={styles.canvasCard}>
        <Icon className={styles.closeIcon} icon={'cross'} title={'Закрыть'} onClick={() => toggleFrame(false)} />
        <FrameControls />
        <div className={styles.canvasInner}>
          <Canvas bg={bg} locations={location} />
        </div>
      </div>
    </Card>
  )
})

const SchemeContent = observer(() => {
  const store = useStore()

  const projects = toJS(store.projects.list)
  const currentProject = projects.find(item => item.id === store.projects.current.id)
  const imageOverlayUrl = currentProject?.scheme_file || '/factory_2.png'
  const imageOverlayFactoryX = currentProject?.scheme_max_x || FACTORY_X
  const imageOverlayFactoryY = currentProject?.scheme_max_y || FACTORY_Y

  const [mapRef, startEdit] = useEditablePoly({
    onCreated: store.projectScheme.enterAOICreatedMode,
    onCancel: store.projectScheme.deselectAOI,
    imageOverlayFactoryY,
  })
  const [frameOpened, setFrameOpened] = useState(false)
  const handleAOISelect = useCallback(
    id => {
      store.projectScheme.setSelectedAOI(id)
    },
    [store.projectScheme]
  )

  const handleAOIEdit = useCallback(
    id => {
      store.projectScheme.setSelectedAOI(id)
      store.projectScheme.setAOIEditState('edit')
    },
    [store.projectScheme]
  )

  const handleAOICoordsUpdate = useCallback(
    (id, points) => {
      store.projectScheme.setAOICoordinates(id, points)
    },
    [store.projectScheme]
  )

  let loading = false
  if (!store.projects.current) {
    loading = true
  }
  if (store.projectScheme.progress === 'work') {
    loading = true
  }

  let lines = null
  if (store.projectScheme.segments.length && store.shared.selectedOI) {
    lines = store.projectScheme.segments.map((line, i) => {
      return <GeoJSON key={line.key} color={store.shared.selectedOI.color} weight={2} data={line} />
    })
  }

  let aoi = null
  if (store.projectScheme.showAoi) {
    aoi = store.projectScheme.aoi.map(aoi => {
      return (
        <Poly
          key={`aoi-${aoi.id}`}
          id={aoi.id}
          positions={aoi.points}
          color={aoi.color}
          onUpdate={handleAOICoordsUpdate}
          onSelect={handleAOISelect}
          onEdit={handleAOIEdit}
          selected={store.projectScheme.selectedAOI?.id === aoi.id}
          imageOverlayFactoryY={imageOverlayFactoryY}
        />
      )
    })
  }

  const [, point] = store.projectScheme.frame || []

  let icon = null
  if (store.shared.selectedOI) {
    const color = invertColor(store.shared.selectedOI.color)
    if (icons[color]) {
      icon = icons[color]
    } else {
      icon = L.icon({
        iconUrl: `data:image/svg+xml;base64, ${btoa(svg(color))}`,
        iconAnchor: [20, 20],
      })
      icons[color] = icon
    }
  }


  return (
    <div className={styles.pageContent}>
      <AOIEditDialog />
      <AOIAddDialog />
      <Overlay
        isOpen={frameOpened}
        canOutsideClickClose
        canEscapeKeyClose
        enforceFocus
        onClose={() => {
          setFrameOpened(false)
        }}
      >
        <div className={styles.canvas}>
          <FrameViewer toggleFrame={setFrameOpened} frameOpened={frameOpened} />
        </div>
      </Overlay>
      {loading || !currentProject ? (
        <SpinnerComponent />
      ) : (
        <Map
          ref={mapRef}
          editable
          zoomSnap={0}
          minZoom={-20}
          maxZoom={0}
          zoom={-2}
          crs={L.CRS.Simple}
          bounds={[
            [0, 0],
            [imageOverlayFactoryY, imageOverlayFactoryX],
          ]}
        >
          {store.projectScheme.showAoi && (
            <Control position={'topleft'}>
              <button onClick={startEdit}>Добавить область</button>
            </Control>
          )}
          <ImageOverlay
            url={imageOverlayUrl}
            bounds={[
              [0, 0],
              [imageOverlayFactoryY, imageOverlayFactoryX],
            ]}
          />
          {point && icon && (
            <Marker
              position={[Math.abs(point.y - imageOverlayFactoryY), point.x]}
              icon={icon}
              onclick={() => setFrameOpened(true)}
            />
          )}
          {lines}
          {aoi}
        </Map>
      )}
    </div>
  )
})

const SchemePage = observer(() => {
  const store = useStore()

  let range = null
  if (
    store.projectScheme.totalRange !== null &&
    store.projectScheme.fromDate !== null &&
    store.projectScheme.toDate !== null
  ) {
    const [min, max] = store.projectScheme.totalRange
    range = (
      <DateRangePicker
        min={new Date(min)}
        max={new Date(max)}
        from={store.projectScheme.fromDate}
        to={store.projectScheme.toDate}
        onFromChange={store.projectScheme.setFromDate}
        onToChange={store.projectScheme.setToDate}
        onDateChange={store.projectScheme.setDate}
      />
    )
  }

  let loading = false
  if (!store.projects.current) {
    loading = true
  }
  if (store.projectScheme.progress === 'work') {
    loading = true
  }

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        {loading ? (
          <SpinnerComponent size={20} />
        ) : (
          <>
            <OISelect />
            <Divider />
            <Checkbox onChange={store.projectScheme.toggleShowAoi} value={store.projectScheme.showAoi} label={'ОИ'} />
            <Divider />
            {range}
          </>
        )}
      </div>
      <Switch>
        <Route exact path={SCHEME}>
          <SchemeContent />
        </Route>
        <Route exact path={STATS}>
          <StatsContent />
        </Route>
      </Switch>
      <div className={styles.controls}>{loading ? <SpinnerComponent size={20} /> : <FrameControls />}</div>
      <div className={styles.controls}>
        {loading ? (
          <SpinnerComponent size={20} />
        ) : store.projectScheme.currentPointIndex !== null && store.projectScheme.pointsRange.length !== 0 ? (
          <Slider
            stepSize={1}
            min={0}
            labelStepSize={
              store.projectScheme.pointsRange.length - 1 < 10 ? 1 : (store.projectScheme.pointsRange.length - 1) / 10
            }
            max={store.projectScheme.pointsRange.length - 1}
            value={store.projectScheme.currentPointIndex}
            onChange={v => store.projectScheme.setPointIndex(v)}
          />
        ) : null}
      </div>
    </div>
  )
})

export default SchemePage
