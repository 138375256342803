import { types } from 'mobx-state-tree'
import { SessionStore } from 'store/session'
import { SharedStore } from 'store/shared'
import { RouterModel } from 'mst-react-router'
import { ProjectsStore } from 'store/projects'
import * as ROUTES from 'pages/routes'
import { path } from 'pages/routes'
import { matchPath } from 'react-router'
import { ProjectSchemeStore } from './projectScheme'

export const RootStore = types
  .model('RootStore', {
    projects: ProjectsStore,
    //cameras: CamerasStore,
    shared: SharedStore,
    session: SessionStore,
    projectScheme: ProjectSchemeStore,
    router: RouterModel,
  })
  .actions(self => {
    return {
      relocate(params = {}) {
        const match = matchPath(self.router.location.pathname, {
          path: [
            ROUTES.AUTH,
            ROUTES.ROOT,
            ROUTES.SCHEME,
            ROUTES.STATS,
            ROUTES.PROJECT,
            ROUTES.CAMERAS,
            ROUTES.CAMERA_CALIBRATION,
          ],
          exact: true,
          strict: true,
        })
        if (match) {
          const match2 = matchPath(self.router.location.pathname, { path: match.path, exact: true, strict: true })
          if (match2) {
            self.router.replace(
              path(match.path, {
                ...match2.params,
                ...params,
              })
            )
          }
        }
      },
    }
  })
