import { useState, useRef, useEffect, MutableRefObject } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export default function useResize() {
  const ref = useRef(null)
  const [bounds, updateBounds] = useState({ x: 0, y: 0, left: 0, top: 0, bottom: 0, right: 0, width: 0, height: 0 })

  useEffect(() => {
    const observer = new ResizeObserver(([target]) => updateBounds(target.contentRect))
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect()
  }, [])

  return [{ ref }, bounds]
}
