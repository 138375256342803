import { types, flow, applySnapshot } from 'mobx-state-tree'
import axios from 'axios'
/*
{
    "pk": 1,
    "username": "admin",
    "email": "d@example.com",
    "first_name": "",
    "last_name": ""
}
 */

const checkAuth = async () => {
  try {
    const res = await axios.get('/api/rest-auth/user/', { withCredentials: true })
    return res.data
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Auth error', err)
  }
  return null
}

const logInReq = async (username, password) => {
  try {
    await axios.post(
      '/api/rest-auth/login/',
      {
        username,
        password,
      },
      { withCredentials: true }
    )
    const userData = await checkAuth()
    if (userData) {
      return [null, userData]
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Auth error: ', err)
    return [err.response?.data?.detail || 'При запросе произошла ошибка', null]
  }
  return ['При запросе произошла ошибка', null]
}

export const SessionStore = types
  .model('AuthStore', {
    authenticated: types.boolean,
    progress: types.string,
    error: types.string,
    pk: types.number,
    userName: types.string,
    email: types.string,
    firstName: types.string,
    lastName: types.string,
  })
  .actions(self => {
    const init = flow(function* initSessionStore() {
      self.progress = 'work'
      const userData = yield checkAuth()
      if (userData) {
        applySnapshot(self, {
          progress: 'success',
          error: '',
          authenticated: true,
          email: userData.email,
          firstName: userData.first_name,
          lastName: userData.last_name,
          pk: userData.pk,
          userName: userData.username,
        })
      } else {
        self.progress = 'error'
        self.authenticated = false
      }
    })
    const logIn = flow(function* logInAction(username, password) {
      self.progress = 'work'
      const [error, userData] = yield logInReq(username, password)
      if (userData) {
        applySnapshot(self, {
          progress: 'success',
          error: '',
          authenticated: true,
          email: userData.email,
          firstName: userData.first_name,
          lastName: userData.last_name,
          pk: userData.pk,
          userName: userData.username,
        })
      }
      if (error) {
        self.progress = 'error'
        self.authenticated = false
        self.error = error
      }
    })
    const logOut = flow(function* logOut() {
      try {
        self.progress = 'work'
        yield axios.post('/api/rest-auth/logout/', null, { withCredentials: true })
        self.progress = 'idle'
        self.authenticated = false
      } catch (e) {
        self.progress = 'idle'
        self.authenticated = false
      }
    })
    return {
      afterCreate() {
        init()
      },
      logIn,
      logOut,
    }
  })
