import { flow, types, getRoot } from 'mobx-state-tree'
import { Video } from 'store/types/video'
import axios from 'axios'
import { reaction } from 'mobx'
import qs from 'qs'

export const ProjectsListItem = types
  .model('ProjectsListItem', {
    id: types.identifierNumber,
    name: types.string,
    scheme_file: types.maybeNull(types.string),
    scheme_max_y: types.number,
    scheme_max_x: types.number,
    row_num: types.number,
    col_num: types.number,
  })
  .views(self => ({
    get row() {
      return self.row_num ?? 0
    },
    get col() {
      return self.col_num ?? 0
    },
  }))

export const Project = types
  .model('Project', {
    id: types.identifierNumber,
    metadata: types.reference(ProjectsListItem),
    progress: types.string,
    _videos: types.array(Video),
    currentFrame: types.number,
    selectedVideo: types.maybeNull(types.reference(Video)),
    dates: types.array(types.string),
    selectedDate: types.maybe(types.string),
  })
  .views(self => ({
    get videos() {
      if (self.selectedDate) {
        return self._videos.filter(video => {
          return video.day === self.selectedDate
        })
      }
      return self._videos
    },
    get baseVideo() {
      if (!self._videos.length) {
        return null
      }
      return self._videos[0].baseVideo
    },
  }))
  .actions(self => {
    const init = flow(function*() {
      try {
        const [videosRes, datesRes] = yield Promise.all([
          axios.get(`/api/video/?${qs.stringify({ project: self.id, start: self.selectedDate })}`, {
            withCredentials: true,
          }),
          axios.get(`/api/project-dates/?project=${self.id}`, { withCredentials: true }),
        ])

        const dates = [...datesRes.data]
        const selectedDate = dates[0]
        const videos = videosRes.data.results

        self._videos = []
        self._videos = videos.map(v => ({
          ...v,
          // TODO remove
          roi_top_left_x: 0,
          roi_top_left_y: 0,
          roi_bottom_right_x: 0,
          roi_bottom_right_y: 0,
          camera_top_left_x: 0,
          camera_top_left_y: 0,
          camera_bottom_right_x: 0,
          camera_bottom_right_y: 0,
        }))
        self.dates = datesRes.data
        self.selectedDate = self.selectedDate ? self.selectedDate : selectedDate
        self.progress = 'success'
      } catch (err) {
        console.error('Project load error:')
        console.error(err)
      }
    })
    const setCurrentFrame = index => {
      self.currentFrame = index
      const root = getRoot(self)
      root.relocate({ frameId: index })
    }
    const setSelectedDate = date => {
      self.selectedDate = date
      const root = getRoot(self)
      root.relocate({ date: date })
    }
    return {
      init,
      afterAttach() {
        reaction(
          () => self.selectedDate,
          () => self.init()
        )
        self.init()
      },
      setCurrentFrame,
      setSelectedVideo(videoId) {
        self.selectedVideo = videoId
        const root = getRoot(self)
        root.relocate({ cameraId: videoId })
      },
      setSelectedDate,
    }
  })
