const interpolate = (template, params = {}) => {
  let result = template
  let count = 0
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === 'undefined') {
      return
    }
    const param = `:${key}`
    if (template.includes(param)) {
      result = result.replace(param, value)
      count += 1
    }
  })
  return [result, count]
}

export const path = (template, params = {}) => {
  let url = ''
  let interpolationsCount = 0
  if (Array.isArray(template)) {
    const processed = template.map(tmp => interpolate(tmp, params))
    let maxIndex = 0
    let maxValue = 0
    for (let i = 0; i < processed.length; i++) {
      const [, count] = processed[i]
      if (count > maxValue) {
        maxValue = count
        maxIndex = i
      }
    }
    url = processed[maxIndex][0]
    interpolationsCount = maxValue
  } else {
    const [interpolatedString, count] = interpolate(template, params)
    url = interpolatedString
    interpolationsCount = count
  }

  if (interpolationsCount === 0 && Object.keys(params).length) {
    throw new Error(`URL params error: nothing to interpolate. Parameter templates and properties doesnt match.`)
  }

  if (!url) {
    throw new Error('URL params error: url is empty')
  }

  const missedParamsMatch = url.match(/:\w+/gi)
  if (missedParamsMatch) {
    throw new Error(
      `URL params error: some properties not replaced [${missedParamsMatch.join(
        ','
      )}], check your template and parameters, template ${JSON.stringify(template)}`
    )
  }

  return url
}

export const PROJECT = '/project/:projectId'
export const CAMERAS = [
  '/project/:projectId/cameras',
  '/project/:projectId/cameras/date/:date',
  '/project/:projectId/cameras/frame/:frameId',
  '/project/:projectId/cameras/date/:date/frame/:frameId',
]
export const SCHEME = ['/project/:projectId/scheme', '/project/:projectId/scheme/frame/:frameId']

export const STATS_LIST = ['/project/:projectId/stats', '/project/:projectId/stats/frame/:frameId']
export const STATS_ITEM = ['/project/:projectId/stats/:reportId']
export const STATS = [...STATS_LIST, ...STATS_ITEM]

export const CAMERA_CALIBRATION = [
  '/calibration/:projectId',
  '/calibration/:projectId/date/:date',
  '/calibration/:projectId/camera/:cameraId',
  '/calibration/:projectId/camera/:cameraId/date/:date',
  '/calibration/:projectId/frame/:frameId',
  '/calibration/:projectId/frame/:frameId/date/:date',
  '/calibration/:projectId/camera/:cameraId/frame/:frameId',
  '/calibration/:projectId/camera/:cameraId/frame/:frameId/date/:date',
]
export const AUTH = '/auth'
export const ROOT = '/'
export const TEST = '/test'

export const toCameras = (projectId, frameId, date) => {
  return path(CAMERAS, { projectId, frameId, date })
}
export const toScheme = (projectId, frameId, date) => {
  return path(SCHEME, { projectId, frameId, date })
}
export const toCalibration = (projectId, frameId, cameraId, date) => {
  return path(CAMERA_CALIBRATION, { projectId, frameId, cameraId, date })
}
export const toStats = (projectId, frameId, date) => {
  return path(STATS, { projectId, frameId, date })
}
export const toStatsItem = (projectId, reportId) => {
  return path(STATS_ITEM, { projectId, reportId })
}