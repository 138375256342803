import { applySnapshot, flow, getParent, types } from 'mobx-state-tree'
import { OI } from 'store/types/oi'

export const FrameLocationApiModel = types
  .model({
    id: types.identifierNumber,
    created: types.string,
    updated: types.string,
    x: types.number,
    y: types.number,
    w: types.number,
    h: types.number,
    oi: types.reference(OI),
  })
  .views(self => ({
    get color() {
      return self.oi.color
    },
  }))
  .actions(self => {
    const remove = flow(function* removeLocation() {
      getParent(self, 2).removeLocation(self)
    })
    const update = snapshot => {
      applySnapshot(self, {
        ...self,
        ...snapshot,
      })
    }
    return {
      remove,
      update,
    }
  })
export const FrameLocation = types.snapshotProcessor(FrameLocationApiModel, {
  postProcessor(sn) {
    return {
      ...sn,
      x: sn.x.toFixed(5),
      y: sn.y.toFixed(5),
      W: sn.w.toFixed(5),
      h: sn.h.toFixed(5),
    }
  },
  preProcessor(sn) {
    // 💩
    return {
      ...sn,
      x: parseFloat(sn.x),
      y: parseFloat(sn.y),
      w: parseFloat(sn.w),
      h: parseFloat(sn.h),
    }
  },
})
