import { types, flow, getRoot, applySnapshot } from 'mobx-state-tree'
import axios from 'axios'
import { reaction } from 'mobx'
import { matchPath } from 'react-router'
import { SCHEME, STATS } from '../pages/routes'
import { Frame } from 'store/types/frame'
import { OI } from './types/oi'
import { xy } from '../helpers/xy'
import { AOI } from './types/aoi'

const INTERVAL = 12

const match = pathname => {
  return matchPath(pathname, {
    path: [...SCHEME, ...STATS],
    strict: true,
    exact: true,
  })
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

const Report = types
  .model('Report', {
    id: types.number,
    interval: types.number,
    ois: types.array(types.reference(OI)),
    date_start: types.number,
    date_end: types.number,
    created_by: types.maybeNull(types.number),
    project: types.number,
    created: types.string,
    result: types.maybeNull(
      types.model({
        end_time: types.number,
        start_time: types.number,
        total_distance: types.string,
        out_of_map_time: types.maybeNull(types.number),
        rows: types.array(
          types.model({
            oi: types.model({
              pk: types.number,
              name: types.string,
            }),
            aoi: types.maybeNull(
              types.model({
                pk: types.number,
                name: types.string,
              })
            ),
            distance: types.union(types.string, types.number, types.undefined),
            distance_before: types.union(types.string, types.number, types.undefined),
            end_time: types.number,
            out_of_map: types.boolean,
            start_time: types.number,
          })
        ),
      })
    ),
  })
  .views(self => ({
    get createdAt() {
      return new Date(self.created)
    },
  }))
  .actions(self => {
    let detached = false
    const afterAttach = flow(function*() {
      if (!self.result) {
        while (true) {
          if (detached) {
            return
          }
          let data
          try {
            const res = yield axios.get(`/api/reports/${self.id}/`)
            data = res.data
          } catch (err) {
            console.error('Report data fetch error!')
            break
          }
          if (detached) {
            return
          }
          if (!data.result) {
            yield delay(10000)
            continue
          }
          applySnapshot(self, data)
          break
        }
      }
    })
    const beforeDestroy = () => {
      detached = true
    }
    return {
      afterAttach,
      beforeDestroy,
    }
  })

export const ProjectSchemeStore = types
  .model('ProjectSchemeStore', {
    showAoi: types.boolean,
    progress: types.string,
    _segments: types.array(
      types.model('Segment', {
        dist: types.number,
        start_time: types.number,
        stop_time: types.number,
        interval: types.number,
        points: types.array(
          types.model('SegmentPoint', {
            x: types.number,
            y: types.number,
            frame_id: types.number,
            floc_id: types.number,
            time: types.number,
          })
        ),
      })
    ),
    from: types.number,
    to: types.number,
    _fromDate: types.maybeNull(types.Date),
    _toDate: types.maybeNull(types.Date),
    _framesCache: types.map(Frame),
    currentPointIndex: types.maybeNull(types.number),
    reports: types.array(Report),
    reportCreatingProgress: types.string,
    reportsFetchProgress: types.string,
    selectedAOI: types.maybeNull(types.reference(AOI)),
    aoiEditState: types.string, // none, edit, add,
    aoiDraftCoords: types.maybeNull(types.array(types.array(types.number))),
  })
  .views(self => ({
    get report() {
      const fromDate = self.fromDate
      const toDate = self.toDate
      if (!fromDate || !toDate) {
        return null
      }

      if (self.reports.length) {
        const root = getRoot(self)
        const project = root.projects.current
        if (!project) {
          return null
        }
        const report = self.reports.find(report => project.id === report.project)
        if (!report) {
          return null
        }

        if (!report.result) {
          return {
            id: report.id,
            empty: true,
            createdAt: report.createdAt,
          }
        }

        const from = fromDate.getTime()
        const to = toDate.getTime()

        const rows = report.result.rows
          .slice()
          .filter(row => {
            const end = row.end_time * 1000
            const start = row.start_time * 1000
            if (end > to) {
              return false
            }
            if (start < from) {
              return false
            }
            return true
          })
          .sort((a, b) => a.start_time - b.start_time)
        return {
          ...report.result,
          rows,
          id: report.id,
          oi: true,
          createdAt: report.createdAt,
        }
      }
      return undefined
    },

    get currentOIReport() {
      const report = self.report
      if (!report) {
        return null
      }
      const root = getRoot(self)
      const selectedOI = root.shared.selectedOI
      if (!selectedOI) {
        return {
          id: report.id,
          empty: true,
          oi: false,
          createdAt: report.createdAt,
        }
      }
      return {
        ...report,
        rows: report.rows.filter(row => selectedOI.id === row.oi.pk),
      }
    },

    get statsTableData() {
      const report = self.currentOIReport
      if (!report) {
        return []
      }
      const groups = {
        no_group: {
          name: 'На карте',
          id: null,
          time: 0,
          distance: 0,
        },
        out_of_map: {
          name: 'Вне карты',
          id: null,
          time: 0,
          distance: 'N/A',
        },
      }
      for (let i = 0; i < report.rows.length; i++) {
        const row = report.rows[i]
        if (row.out_of_map) {
          groups.out_of_map.time += row.end_time - row.start_time
          continue
        }
        let pointer = null
        if (!row.aoi) {
          pointer = 'no_group'
        } else {
          const aoi = row.aoi
          if (!groups[aoi.pk]) {
            groups[aoi.pk] = {
              name: aoi.name,
              id: aoi.pk,
              time: 0,
              distance: 0,
              out_of_map: 0,
            }
          }
          pointer = aoi.pk
        }

        groups[pointer].time += row.end_time - row.start_time
        groups[pointer].distance += parseFloat(row.distance) / 100
      }
      return Object.values(groups)
    },

    get statsChartData() {
      const report = self.currentOIReport
      if (!report) {
        return []
      }
      const aoi = self.aoi
      const list = report.rows.reduce((buf, row) => {
        const distanceBefore = parseFloat(row.distance_before) / 100
        const distance = parseFloat(row.distance) / 100
        const item1 = {
          distance: distanceBefore,
          time: row.start_time * 1000,
        }
        const item2 = {
          distance: distance + distanceBefore,
          time: row.end_time * 1000,
        }
        const data = [item1, item2]
        const aoiItem = row.aoi?.pk ? aoi.find(aoi => aoi.id === row.aoi.pk) : null

        buf.push({ data, aoi: aoiItem, out_of_map: row.out_of_map })

        return buf
      }, [])

      return {
        list,
        aoi: aoi,
      }
    },

    get allOIChartData() {
      const report = self.report
      if (!report) {
        return []
      }

      const oi = getRoot(self).shared.oi
      const oiMap = {}
      oi.forEach(oi => {
        oiMap[oi.id] = oi
      })
      const list = {}
      report.rows.forEach(row => {
        if (!list[row.oi.pk]) {
          list[row.oi.pk] = {
            oi: row.oi.pk,
            name: row.oi.name,
            color: oiMap[row.oi.pk].color,
            list: [],
          }
        }
        const distanceBefore = parseFloat(row.distance_before) / 100
        const distance = parseFloat(row.distance) / 100
        const item1 = {
          distance: distanceBefore,
          time: row.start_time * 1000,
        }
        const item2 = {
          distance: distanceBefore + distance,
          time: row.end_time * 1000,
        }

        list[row.oi.pk].list.push({ data: [item1, item2], out_of_map: row.out_of_map })
      })

      return Object.values(list)
    },

    get reportStats() {
      const report = self.currentOIReport
      if (!report) {
        return {
          distance: 0,
          avgSpeed: 0,
          totalTime: 0,
          outOfMapTime: 0,
        }
      }

      let distance = 0
      let totalTime = 0
      let outOfMapTime = 0
      report.rows.forEach(row => {
        distance += parseFloat(row.distance)
        if (row.out_of_map) {
          outOfMapTime += row.end_time - row.start_time
        } else {
          totalTime += row.end_time - row.start_time
        }
      })

      distance /= 100

      const avgSpeed = distance / 1000 / (totalTime / 60 / 60)

      return {
        distance: distance || 0,
        avgSpeed: avgSpeed || 0,
        totalTime: totalTime || 0,
        outOfMapTime: outOfMapTime || 0,
      }
    },

    get frame() {
      const point = self.currentPoint
      if (point === null) {
        return null
      }
      const frame = self._framesCache.get(point.frame_id)
      if (!frame) {
        return null
      }
      return [frame, point]
    },
    get baseVideo() {
      const project = getRoot(self).projects.current
      if (!project) {
        return null
      }
      const baseVideo = project.baseVideo
      if (!baseVideo) {
        return null
      }
      return baseVideo
    },

    get videos() {
      const project = getRoot(self).projects.current
      if (!project) {
        return []
      }
      return project.videos
    },

    get totalRange() {
      const videos = self.videos
      if (!videos.length) {
        return null
      }
      const firstVideo = videos.slice().sort((a, b) => a.startDate.getTime() - b.startDate.getTime())[0]
      const lastVideo = videos.slice().sort((a, b) => b.endDate.getTime() - a.endDate.getTime())[0]

      return [firstVideo.startDate, lastVideo.endDate]
    },

    // get totalRange() {
    //   const baseVideo = self.baseVideo
    //   if (!baseVideo) {
    //     return null
    //   }
    //   return [baseVideo.startDate, baseVideo.endDate]
    // },

    get fromDate() {
      if (this._fromDate !== null) {
        return this._fromDate
      }
      const [from = null] = this.totalRange ?? []
      if (from) {
        return new Date(from)
      }
      return from
    },
    get toDate() {
      if (this._toDate !== null) {
        return this._toDate
      }
      const [, to = null] = this.totalRange ?? []
      if (to) {
        return new Date(to)
      }
      return to
    },

    get preCalculatedGeometries() {
      const baseVideo = self.baseVideo
      if (!baseVideo) {
        return []
      }
      if (!self._segments.length) {
        return []
      }

      const project = getRoot(self).projects.current
      const scheme_max_y = project.metadata.scheme_max_y

      return self._segments
        .map((segment, sIdx) => {
          const result = []
          const l = segment.points.length - 1
          for (let i = 0; i < l; i++) {
            const current = segment.points[i]
            const next = segment.points[i + 1]
            result.push({
              type: 'LineString',
              key: `${sIdx}-${i}`,
              from: current.time,
              to: next.time,
              debugFrom: new Date(current.time),
              debugTo: new Date(next.time),
              coordinates: [xy([current.x, current.y], scheme_max_y), xy([next.x, next.y], scheme_max_y)],
            })
          }
          return result
        })
        .flat()
    },

    get points() {
      if (!self._segments.length) {
        return []
      }
      return self._segments.map(seg => seg.points).flat()
    },

    get pointsRange() {
      if (self.fromDate === null || self.toDate === null) {
        return []
      }

      const fromU = self.fromDate.getTime()
      const toU = self.toDate.getTime()

      let firstPointIdx = null
      let lastPointIdx = null

      const l = self.points.length
      for (let i = 0; i < l; i++) {
        const current = self.points[i]
        const time = new Date(current.time)
        const ii = i + 1
        const next = ii < l ? this.points[i] : null

        if (firstPointIdx === null) {
          if (fromU <= time) {
            firstPointIdx = i
          }
        }

        if (lastPointIdx === null) {
          if (next === null) {
            lastPointIdx = i
            break
          }
          if (toU <= time) {
            lastPointIdx = i
            break
          }
        }
      }
      if (firstPointIdx === null || lastPointIdx === null) {
        return []
      }

      return self.points.slice(firstPointIdx, lastPointIdx)
    },

    get currentPoint() {
      const i = self.currentPointIndex
      const range = self.pointsRange
      if (i > range.length) {
        return null
      }
      const point = self.pointsRange[i]
      if (!point) {
        return null
      }
      return point
    },

    get currentFrameId() {
      const i = self.currentPointIndex
      const range = self.pointsRange
      if (i > range.length) {
        return null
      }
      const point = self.pointsRange[i]
      if (!point) {
        return null
      }
      return point.frame_id
    },

    get segments() {
      if (self.fromDate === null || self.toDate === null) {
        return []
      }
      const fromU = self.fromDate.getTime()
      const toU = self.toDate.getTime()

      let firstSegmentIdx = null
      let lastSegmentIdx = null
      const l = this.preCalculatedGeometries.length
      for (let i = 0; i < l; i++) {
        const current = this.preCalculatedGeometries[i]
        const ii = i + 1
        const next = ii < l ? this.preCalculatedGeometries[ii] : null
        if (firstSegmentIdx === null) {
          if (fromU <= current.from) {
            firstSegmentIdx = i
          }
        }
        if (lastSegmentIdx === null) {
          if (toU <= current.from) {
            lastSegmentIdx = i
            break
          }
          if (next === null) {
            lastSegmentIdx = i
            break
          }
        }
      }
      if (firstSegmentIdx === null || lastSegmentIdx === null) {
        return []
      }
      return self.preCalculatedGeometries.slice(firstSegmentIdx, lastSegmentIdx)
    },

    get hasNextPoint() {
      const range = self.pointsRange
      const i = self.currentPointIndex

      if (!range) {
        return false
      }

      if (i === null) {
        return false
      }

      if (i >= range.length - 1) {
        return false
      }
      return true
    },

    get hasPrevPoint() {
      const range = self.pointsRange
      const i = self.currentPointIndex

      if (!range) {
        return false
      }

      if (i === null) {
        return false
      }

      if (i === 0) {
        return false
      }
      return true
    },

    get aoi() {
      const root = getRoot(self)
      const project = getRoot(self).projects.current
      const scheme_max_y = project.metadata.scheme_max_y
      const list = root.shared.aoi
        .filter(aoi => aoi.project === root.projects.current.id)
        .map(aoi => {
          const color = aoi.color
          const points = aoi.geometry.map(p => [scheme_max_y - p.y, p.x])
          return {
            color,
            points,
            id: aoi.id,
            name: aoi.name,
          }
        })
      return list
    },
  }))
  .actions(self => {
    const fetchData = flow(function*(interval = INTERVAL) {
      const root = getRoot(self)
      const oi = root.shared.selectedOI
      if (!oi) {
        return
      }
      const totalRange = self.totalRange
      if (!totalRange) {
        return
      }

      const [startRange, endRange] = totalRange
      const startTime = startRange.getTime() / 1000
      const endTime = endRange.getTime() / 1000

      const matchedUrlData = match(root.router.location.pathname)
      if (!matchedUrlData) {
        return
      }
      const { params } = matchedUrlData
      try {
        self.progress = 'work'
        const { data } = yield axios.get(
          `/api/route/?oi=${oi.id}&interval=${interval}&project=${params.projectId}&date_start=${startTime}&date_end=${endTime}`
        )
        self._segments = (data || []).map(segment => {
          return {
            ...segment,
            points: segment.points.map(point => ({
              ...point,
              time: new Date(point.time * 1000).getTime(),
            })),
          }
        })
        self.progress = 'success'
      } catch (err) {
        console.error('Routes fetch error!', err)
        self.progress = 'error'
      }
    })

    const fetchReports = flow(function*() {
      self.reportsFetchProgress = 'work'
      try {
        const { data } = yield axios.get('/api/reports/?limit=1000')
        self.reports = data.results
        self.reportsFetchProgress = 'success'
      } catch (err) {
        self.reportsFetchProgress = 'error'
        throw err
      }
    })

    const route = flow(function*(location) {
      const routeMatch = match(location.pathname)
      if (!routeMatch) {
        return
      }
      self.setDate([null, null])
      const root = getRoot(self)

      self.fetchReports()

      const { params } = routeMatch
      const oiId = root.shared.selectedOI?.id
      if (!oiId || !params || self.progress !== 'idle') {
        return
      }
      yield fetchData()
    })

    const afterAttach = () => {
      const root = getRoot(self)
      reaction(
        () => root.shared.selectedOI,
        oi => {
          if (oi) {
            self.fetchData()
          }
        }
      )
      reaction(
        () => self.currentPoint,
        point => {
          if (point !== null && !self._framesCache.has(point.frame_id)) {
            self.fetchFrame(point.frame_id)
          }
        }
      )
      // reaction(
      //   () => self.segments,
      //   segments => {
      //     if (segments === null) {
      //       self.setFrameId(null)
      //       return
      //     }
      //   }
      // )
      reaction(
        () => self.pointsRange,
        range => {
          const i = self.currentPointIndex
          if (range.length) {
            if (i === null) {
              self.setPointIndex(0)
              return
            }
            if (i > range.length) {
              self.setPointIndex(range.length - 1)
              return
            }
            return
          }
          self.setPointIndex(null)
        }
      )
    }

    const setFrom = num => {
      self.from = num
    }
    const setTo = num => {
      self.to = num
    }

    const setFromDate = date => {
      self._fromDate = date
    }

    const setToDate = date => {
      self._toDate = date
    }

    const setDate = ([from, to]) => {
      self._fromDate = from
      self._toDate = to
    }

    const fetchFrame = flow(function*(id) {
      if (self._framesCache.has(String(id))) {
        return
      }
      const { data } = yield axios.get(`/api/frame/${id}/`, { withCredentials: true })
      const frame = data
        ? {
            id: data.id,
            name: data.name,
            img_file: data.img_file,
            created: data.created,
            updated: data.updated,
            video: data.video,
            project: data.project_id,
            index: id,
          }
        : null

      if (frame) {
        self._framesCache.set(String(id), frame)
      }
    })

    const setPointIndex = index => {
      self.currentPointIndex = index
    }

    const prevPoint = () => {
      self.currentPointIndex = self.currentPointIndex === 0 ? 0 : self.currentPointIndex - 1
    }

    const nextPoint = () => {
      self.currentPointIndex =
        self.pointsRange.length - 1 === self.currentPointIndex ? self.currentPointIndex : self.currentPointIndex + 1
    }

    const setAoiState = state => {
      self.showAoi = state
    }

    const toggleShowAoi = () => {
      self.showAoi = !self.showAoi
    }

    const createReport = flow(function*() {
      const root = getRoot(self)
      const fromDate = self.fromDate
      const toDate = self.toDate
      if (!fromDate || !toDate) {
        return
      }
      self.reportCreatingProgress = 'work'
      const project = root.projects.current.id
      const date_start = fromDate.getTime() / 1000
      const date_end = toDate.getTime() / 1000
      const ois = root.shared.oi.map(oi => oi.id)
      try {
        const report = self.report
        if (report) {
          yield axios.delete(`/api/reports/${report.id}/`, { withCredentials: true })
        }
        yield axios.post(
          '/api/reports/',
          { project, date_start, date_end, ois, interval: INTERVAL },
          { withCredentials: true }
        )
        yield self.fetchReports()
        self.reportCreatingProgress = 'success'
      } catch (err) {
        console.log('Report creation error!', err)
        self.reportCreatingProgress = 'error'
      }
    })

    const setSelectedAOI = id => {
      self.selectedAOI = id
    }

    const setCurrentAOIColor = flow(function*(color) {
      if (self.selectedAOI) {
        self.selectedAOI.setColor(color)
      }
    })

    const setAOICoordinates = flow(function*(id, coords) {
      const root = getRoot(self)
      const aoi = root.shared.aoi.find(aoi => aoi.id === id)
      if (aoi) {
        aoi.update({ coords })
      }
    })

    const setCurrentAOICoords = flow(function*(coords) {})

    const setAOIEditState = mode => {
      self.aoiEditState = mode
    }

    const removeCurrentAOI = flow(function*() {
      if (self.selectedAOI) {
        const id = self.selectedAOI.id
        self.selectedAOI = null
        yield getRoot(self).shared.removeAOI(id)
      }
    })

    const updateCurrentAOI = flow(function*(name, color) {
      if (self.selectedAOI) {
        self.selectedAOI.update({ name, color })
      }
    })

    const enterAOICreatedMode = coords => {
      self.aoiEditState = 'add'
      self.aoiDraftCoords = coords
    }

    const createAOI = (name, color) => {
      const coords = self.aoiDraftCoords
      getRoot(self).shared.createAOI({ name, color, coords })
      self.aoiEditState = 'none'
    }

    const deselectAOI = () => {
      self.selectedAOI = null
    }

    return {
      route,
      fetchData,
      fetchReports,
      afterAttach,
      setFrom,
      setTo,
      setFromDate,
      setToDate,
      setDate,
      fetchFrame,
      setPointIndex,
      nextPoint,
      prevPoint,
      setAoiState,
      toggleShowAoi,
      createReport,
      setSelectedAOI,
      setCurrentAOIColor,
      setCurrentAOICoords,
      setAOICoordinates,
      removeCurrentAOI,
      updateCurrentAOI,
      setAOIEditState,
      enterAOICreatedMode,
      createAOI,
      deselectAOI,
    }
  })
