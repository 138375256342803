import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import axios from 'axios'
import { toJS } from 'mobx'
import { useStore } from 'store'
import SpinnerComponent from 'components/Spinner/Spinner'
import { Checkbox, NumericInput, Divider, Button, Popover, PopoverInteractionKind, Tag } from '@blueprintjs/core'
import { OISelect } from 'components/OISelect/OISelect'
import styles from './Controls.module.pcss'
import useKeyCodePress from 'hooks/useKeyCodePress'
import useAutoNextFrame from 'hooks/useAutoNextFrame'

const Help = () => {
  return (
    <Popover
      targetClassName={styles.helpIcon}
      content={
        <div className={styles.popover}>
          <div className={styles.popoverRow}>
            <div className={styles.popoverText}>Выбор сотрудника</div>
            <Tag>1-9</Tag>
          </div>
          <div className={styles.popoverRow}>
            <div className={styles.popoverText}>Сотрудник не выбран</div>
            <Tag>0</Tag>
          </div>
          <div className={styles.popoverRow}>
            <div className={styles.popoverText}>Следующий кадр</div>
            <Tag>Вправо</Tag>
          </div>
          <div className={styles.popoverRow}>
            <div className={styles.popoverText}>Предыдущий кадр</div>
            <Tag>Влево</Tag>
          </div>
          <div className={styles.popoverRow}>
            <div className={styles.popoverText}>Копирование разметки</div>
            <Tag>Пробел</Tag>
          </div>
        </div>
      }
      enforceFocus={false}
      isOpen={undefined}
      interactionKind={PopoverInteractionKind.HOVER}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 548.18 548.18" width="21">
        <path
          fill={'rgb(167, 182, 194)'}
          d="M537.47 120.34a35.21 35.21 0 00-25.84-10.7H36.55a35.2 35.2 0 00-25.84 10.7A35.22 35.22 0 000 146.18v255.81c0 10.1 3.57 18.7 10.7 25.84a35.2 35.2 0 0025.84 10.71h475.09c10.09 0 18.7-3.57 25.84-10.7a35.22 35.22 0 0010.7-25.85V146.18c0-10.09-3.57-18.7-10.7-25.84zM511.63 402H36.55V146.18h475.08v255.81z"
        />
        <path
          fill={'rgb(167, 182, 194)'}
          d="M77.66 365.44h27.4c3.05 0 4.57-1.52 4.57-4.56v-27.41c0-3.04-1.52-4.57-4.56-4.57H77.66c-3.05 0-4.57 1.53-4.57 4.57v27.4c0 3.05 1.52 4.57 4.57 4.57zM77.66 292.36h63.95c3.05 0 4.57-1.53 4.57-4.57v-27.41c0-3.04-1.52-4.57-4.57-4.57H77.66c-3.05 0-4.57 1.52-4.57 4.57v27.41c0 3.04 1.52 4.57 4.57 4.57zM77.66 219.27h27.4c3.05 0 4.57-1.53 4.57-4.57v-27.4c0-3.05-1.52-4.57-4.56-4.58H77.66c-3.05 0-4.57 1.53-4.57 4.57v27.4c0 3.05 1.52 4.58 4.57 4.58zM397.43 328.9H150.75c-3.04 0-4.57 1.53-4.57 4.58v27.4c0 3.04 1.53 4.57 4.57 4.57h246.67c3.05 0 4.57-1.52 4.57-4.57v-27.4c0-3.05-1.52-4.58-4.56-4.58zM182.72 287.79c0 3.04 1.53 4.57 4.57 4.57h27.41c3.05 0 4.57-1.53 4.57-4.57v-27.41c0-3.04-1.52-4.57-4.57-4.57H187.3c-3.04 0-4.57 1.52-4.57 4.57v27.41zM150.75 219.27h27.4c3.05 0 4.58-1.53 4.58-4.57v-27.4c0-3.05-1.53-4.57-4.57-4.58h-27.4c-3.05 0-4.58 1.53-4.58 4.57v27.4c0 3.05 1.53 4.58 4.57 4.58zM255.81 287.79c0 3.04 1.53 4.57 4.57 4.57h27.4c3.06 0 4.58-1.53 4.58-4.57v-27.41c0-3.04-1.53-4.57-4.57-4.57h-27.4c-3.05 0-4.58 1.52-4.58 4.57v27.41zM223.84 219.27h27.4c3.05 0 4.57-1.53 4.57-4.57v-27.4c0-3.05-1.52-4.57-4.57-4.58h-27.4c-3.05 0-4.57 1.53-4.57 4.57v27.4c0 3.05 1.52 4.58 4.57 4.58zM328.9 287.79c0 3.04 1.53 4.57 4.57 4.57h27.41c3.04 0 4.56-1.53 4.56-4.57v-27.41c0-3.04-1.52-4.57-4.56-4.57h-27.41c-3.04 0-4.57 1.52-4.57 4.57v27.41zM470.51 328.9h-27.4c-3.05 0-4.57 1.53-4.57 4.58v27.4c0 3.04 1.52 4.57 4.57 4.57h27.4c3.05 0 4.57-1.52 4.57-4.57v-27.4c0-3.05-1.52-4.58-4.57-4.58zM296.93 219.27h27.4c3.06 0 4.57-1.53 4.57-4.57v-27.4c0-3.05-1.52-4.57-4.56-4.58h-27.41c-3.05 0-4.57 1.53-4.57 4.57v27.4c0 3.05 1.52 4.58 4.57 4.58zM370.02 219.27h27.4c3.05 0 4.57-1.53 4.57-4.57v-27.4c0-3.05-1.52-4.57-4.57-4.58h-27.4c-3.05 0-4.57 1.53-4.57 4.57v27.4c0 3.05 1.52 4.58 4.57 4.58zM402 287.79c0 3.04 1.51 4.57 4.56 4.57h63.95c3.05 0 4.57-1.53 4.57-4.57v-100.5c0-3.04-1.52-4.56-4.57-4.57h-27.4c-3.05 0-4.57 1.53-4.57 4.57v68.52h-31.98c-3.05 0-4.57 1.53-4.57 4.57v27.41z"
        />
      </svg>
    </Popover>
  )
}

const Controls = observer(() => {
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [framesCount, setFramesCount] = useState(undefined)
  const store = useStore()
  const currentOi = toJS(store.shared.selectedOI)
  const [autoNextFrame, , onAutoNextFrameToggle] = useAutoNextFrame()

  const autoNextFrameHandler = useCallback(() => {
    if (autoNextFrame) {
      store.projects.current?.setCurrentFrame(store.projects.current?.currentFrame + 1)
    }
  }, [store, autoNextFrame])

  const nextFrame = useCallback(
    () => store.projects.current?.setCurrentFrame(store.projects.current?.currentFrame + 1),
    [store]
  )
  const prevFrame = useCallback(
    () => store.projects.current?.setCurrentFrame(store.projects.current?.currentFrame - 1),
    [store]
  )
  const onToggle = useCallback(() => onAutoNextFrameToggle(), [onAutoNextFrameToggle])

  const project = store.projects.current

  const onAutoClick = async () => {
    setIsLoading(true)
    const currentFrame = project.currentFrame

    const frames = await Promise.all(
      project.videos.map(video => {
        const order_num = currentFrame + video.offset - 1
        return axios.get(`/api/frame/?video=${video.id}&order_num=${order_num}`, { withCredentials: true })
      })
    )

    const _locations = await Promise.all(
      frames
        .map(frame => frame?.data?.results[0]?.id)
        .filter(item => item)
        .map(frameId => {
          return axios.get(`/api/frame-location/?limit=100&frame=${frameId + 1}`, { withCredentials: true })
        })
    )

    const __locations = _locations.map(item => item.data.results)

    const newFrames = []

    project.videos.forEach((video, pos) => {
      if (__locations[pos]) {
        const locations = currentOi ? __locations[pos].filter(item => item.oi === currentOi.id) : __locations[pos]

        if (locations) {
          locations.forEach(location => {
            newFrames.push(
              new Promise(resolve => {
                video.frame.newLocation(
                  {
                    w: parseFloat(location.w),
                    h: parseFloat(location.h),
                    x: parseFloat(location.x),
                    y: parseFloat(location.y),
                  },
                  { id: location.oi },
                  () => resolve()
                )
              })
            )
          })
        }
      }
    })

    Promise.all(newFrames).then(values => {
      setIsLoading(false)
      setIsOpen(true)
      setFramesCount(values.length)
      setTimeout(() => {
        autoNextFrameHandler()
        setIsOpen(false)
        setFramesCount(undefined)
      }, 500)
    })
  }

  useKeyCodePress(32, onAutoClick)

  if (!project || project.progress !== 'success' || !store.shared.oi.length) {
    return <SpinnerComponent />
  }

  return (
    <>
      <div className={styles.place} />
      <div className={styles.controls}>
        <div className={styles.buttons}>
          <Help />
          <Button icon={'arrow-left'} onClick={prevFrame} />
          <Divider />
          <div style={{ width: '45px' }}>
            <NumericInput
              fill
              value={store.projects.current?.currentFrame}
              onValueChange={value => project.setCurrentFrame(value)}
              buttonPosition={'none'}
            />
          </div>
          <Divider />
          <Button icon={'arrow-right'} onClick={nextFrame} />
          <Divider />
          <div className={styles.checkboxWrapper}>
            <Checkbox
              className={styles.checkbox}
              defaultChecked={autoNextFrame}
              onChange={onToggle}
              label={'Автопереход'}
              large
              inline
            />
          </div>
          <Divider />
          <Popover
            content={
              <div className={styles.popover}>
                {framesCount ? (
                  <div>{`Перенесено ${framesCount} рамок на ${project.videos.length} кадрах`}</div>
                ) : (
                  <div>Нет объектов для копирования️</div>
                )}
              </div>
            }
            enforceFocus={false}
            isOpen={isOpen}
          >
            <Button text={'Перенос разметки'} onClick={onAutoClick} loading={isLoading} />
          </Popover>
          <Divider />
          {store.shared.oi.length && <OISelect />}
        </div>
        {store.shared.selectedOI ? (
          <div className={styles.oiPreviewContainer} style={{ backgroundColor: store.shared.selectedOI.color }}>
            {store.shared.selectedOI.img1 && (
              <img className={styles.oiPreview} src={store.shared.selectedOI.img1} alt="img" />
            )}
            {store.shared.selectedOI.img2 && (
              <img className={styles.oiPreview} src={store.shared.selectedOI.img2} alt="img" />
            )}
            {store.shared.selectedOI.img3 && (
              <img className={styles.oiPreview} src={store.shared.selectedOI.img3} alt="img" />
            )}
            {store.shared.selectedOI.img4 && (
              <img className={styles.oiPreview} src={store.shared.selectedOI.img4} alt="img" />
            )}
          </div>
        ) : (
          <div className={styles.stab} />
        )}
      </div>
    </>
  )
})

export default Controls
