import React from 'react'

export const AutoNextFrameContext = React.createContext([false, () => {}])

export const AutoNextFrameProvider = ({ children }) => {
  const [autoNextFrame, setAutoNextFrame] = React.useState()

  return (
    <AutoNextFrameContext.Provider value={[autoNextFrame, setAutoNextFrame]}>{children}</AutoNextFrameContext.Provider>
  )
}

export const useAutoNextFrame = () => {
  const [autoNextFrame, setAutoNextFrame] = React.useContext(AutoNextFrameContext)

  const onSet = value => {
    setAutoNextFrame(value)
  }

  const onToggle = () => {
    setAutoNextFrame(value => !value)
  }

  return [autoNextFrame, onSet, onToggle]
}

export default useAutoNextFrame
