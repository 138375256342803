import React, { useReducer, useRef } from 'react'
import { observer } from 'mobx-react'
import { useStore } from 'store'
import SpinnerComponent from 'components/Spinner/Spinner'
import Controls from 'components/Controls/Controls'
import MarkupCamera from 'components/MarkupCamera/MarkupCamera'
import { VideoSelect, VideoItemRenderer } from 'components/VideoSelect/VideoSelect'
import { Button, Divider } from '@blueprintjs/core'
import styles from './CameraCalibration.module.pcss'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-javascript.js'
import 'ace-builds/src-noconflict/theme-solarized_dark.js'
import { Map, ImageOverlay, Circle } from 'react-leaflet'
import L from 'leaflet'
import { FACTORY_X, FACTORY_Y } from '../../constants/bounds'
import { toJS } from 'mobx'

const initialCode = `/*
* @param {Object} argv - Arguments map
* @param argv.points - Points list, e.g.: [ [1, 2], [3, 4], [5, 6] ... ] 
* @param argv.camera_bottom_right_x 
* @param argv.camera_bottom_right_x
* @param argv.camera_bottom_right_y
* @param argv.camera_top_left_x
* @param argv.camera_top_left_y
* @param argv.created
* @param argv.end
* @param argv.fps
* @param argv.id
* @param argv.name
* @param argv.roi_bottom_right_x
* @param argv.roi_bottom_right_y
* @param argv.roi_top_left_x: 0
* @param argv.roi_top_left_y: 0
* @param argv.scale
* @param argv.start
* @param argv.updated
* @return [number, number][] Remaped point list, e.g.: [ [10, 20], [30, 40], [50, 60] ... ] 
*/
function main(argv) {
 return argv.points.map(([x, y]) => {
   return [x + 1, y + 1]
 })
}
`

const initialState = {
  pointer: 0,
  cameraPoints: [],
  mapPoints: [],
  error: '',
  result: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'addPoint': {
      const points = [...state.cameraPoints]
      points[state.pointer] = action.payload
      return {
        ...state,
        pointer: (5 + state.pointer + 1) % 5,
        cameraPoints: points,
      }
    }
    case 'clear': {
      return {
        ...initialState,
      }
    }
    case 'exec': {
      const fnBody = `
            ${action.payload.code}
            console.log('argv', argv)
            return main(argv)
          `
      let mapPoints = []
      let error = ''
      try {
        const fn = new Function('argv', fnBody)
        mapPoints = fn({ points: state.cameraPoints, ...action.payload.video })
      } catch (err) {
        console.error(err)
        error = String(err)
      }

      if (!Array.isArray(mapPoints)) {
        mapPoints = []
        error = 'main() must return array!'
      } else if (
        mapPoints.some(p => !Array.isArray(p) || p.length < 2 || p.length > 2 || p.some(v => typeof v !== 'number'))
      ) {
        error = `Invalid returned value: ${JSON.stringify(mapPoints)}`
        mapPoints = []
      }

      localStorage.setItem('C_CODE', action.payload.code)

      return {
        ...state,
        mapPoints,
        error,
        result: JSON.stringify(mapPoints),
      }
    }
    default: {
      return null
    }
  }
}

const colors = ['#2f79ff', '#f89505', '#3ab084', '#fe0112', '#a83299']

const CameraCalibration = observer(() => {
  const store = useStore()
  const project = store.projects.current
  const [state, dispatch] = useReducer(reducer, initialState)
  const codeRef = useRef(localStorage.getItem('C_CODE') || initialCode)

  if (!project || project.progress !== 'success') {
    return <SpinnerComponent />
  }

  const projects = toJS(store.projects.list)
  const currentProject = projects.find(item => item.id === store.projects.current.id)
  const imageOverlayUrl = currentProject?.scheme_file || '/factory_2.png'
  const imageOverlayFactoryX = currentProject?.scheme_max_x || FACTORY_X
  const imageOverlayFactoryY = currentProject?.scheme_max_y || FACTORY_Y

  if (!currentProject) {
    return <SpinnerComponent />
  }

  return (
    <div className={styles.root}>
      <div className={styles.editor}>
        <AceEditor
          placeholder="Placeholder Text"
          mode="javascript"
          theme="solarized_dark"
          name="code"
          width="100%"
          height="100%"
          onLoad={() => {}}
          onChange={code => {
            codeRef.current = code
          }}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={codeRef.current}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </div>
      <div className={styles.video}>
        {project.selectedVideo && (
          <MarkupCamera
            video={project.selectedVideo}
            onClick={point => {
              dispatch({ type: 'addPoint', payload: point })
            }}
            points={state.cameraPoints}
          />
        )}
      </div>
      <div className={styles.map}>
        <Map
          zoomSnap={0}
          minZoom={-2}
          maxZoom={0}
          zoom={-2}
          crs={L.CRS.Simple}
          center={[0, 0]}
          bounds={[
            [0, 0],
            [imageOverlayFactoryY, imageOverlayFactoryX],
          ]}
        >
          <ImageOverlay
            url={imageOverlayUrl}
            bounds={[
              [0, 0],
              [imageOverlayFactoryY, imageOverlayFactoryX],
            ]}
          />
          {state.mapPoints.map((point, i) => (
            <Circle
              key={`circle_${i}`}
              fillColor={colors[i]}
              color={colors[i]}
              radius={2}
              center={[FACTORY_X - point[1], point[0]]}
            />
          ))}
        </Map>
      </div>
      <div className={styles.controls}>
        <VideoSelect
          items={project.videos}
          activeItem={project.selectedVideo}
          itemRenderer={VideoItemRenderer}
          onItemSelect={video => {
            project.setSelectedVideo(video.id)
          }}
          filterable={false}
          popoverProps={{ minimal: true }}
        >
          <Button text={project.selectedVideo ? project.selectedVideo.name : 'Видео не выбрано'} />
        </VideoSelect>
        <Divider />
        <Controls />
        <Divider />
        <Button
          onClick={() =>
            dispatch({
              type: 'exec',
              payload: {
                code: codeRef.current,
                video: { ...project.selectedVideo },
              },
            })
          }
          text="execute"
        />
      </div>
      <div className={styles.out}>
        {state.error && <div className={styles.error}>Error:{state.error}</div>}
        {state.result && <div className={styles.result}>Result:{state.result}</div>}
      </div>
    </div>
  )
})

export default CameraCalibration
