import React from 'react'
import { Classes, Dialog, Icon } from '@blueprintjs/core'
import { ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT, ARROW_UP, REFRESH } from '@blueprintjs/icons/lib/esm/generated/iconNames'

const HelpPopup = ({ isOpen, onClose }) => {
  return (
    <Dialog
      className={Classes.DARK}
      icon="info-sign"
      title="Помощь"
      usePortal={true}
      isOpen={isOpen}
      enforceFocus={true}
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <h3>Список горячих клавиш</h3>
        <ul>
          <li>
            <Icon icon={ARROW_LEFT} /> Предыдущий кадр
          </li>
          <li>
            <Icon icon={ARROW_RIGHT} /> Следующий кадр
          </li>
          <li>
            <Icon icon={REFRESH} /> Колесо мышки - переключение кардров.
          </li>
          <li>
            <Icon icon={ARROW_UP} /> Следующий сотрудник
          </li>
          <li>
            <Icon icon={ARROW_DOWN} /> Предыдущий сотрудник
          </li>
        </ul>
      </div>
    </Dialog>
  )
}

export default HelpPopup
