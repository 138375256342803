import React from 'react'
import { Router, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'
import { observer } from 'mobx-react'
import useStore, { history } from 'store'
import Nav from 'components/Nav/Nav'
import SpinnerComponent from 'components/Spinner/Spinner'
import AuthorizedOnly from 'components/AuthorizedOnly/AuthorizedOnly'
import ProjectPage from 'pages/Project/Project'
import ProjectsPage from 'pages/Projects/Projects'
import AuthPage from 'pages/Auth/Auth'
import CameraCalibration from 'pages/CameraCalibration/CameraCalibration'
import SchemePage from 'pages/ProjectScheme/ProjectScheme'
import T from 'pages/Test/Test'
import * as ROUTES from 'pages/routes'
import 'leaflet/dist/leaflet.css'
import styles from './App.module.pcss'

const Auth = observer(props => {
  const store = useStore()
  const history = useHistory()
  const location = useLocation()

  if (store.session.progress === 'work') {
    return <SpinnerComponent />
  }

  if (!store.session.authenticated && location.pathname !== '/auth') {
    history.replace('/auth')
  }

  return props.children
})

const App = () => {
  return (
    <Router history={history}>
      <div className={`${styles.root} ${Classes.DARK}`}>
        <Auth>
          <AuthorizedOnly>
            <div className={styles.controls}>
              <Nav />
            </div>
          </AuthorizedOnly>
          <div className={styles.page}>
            <Switch>
              <Route path={ROUTES.CAMERAS} exact={true}>
                <ProjectPage />
              </Route>
              <Route path={ROUTES.CAMERA_CALIBRATION} exact={true}>
                <CameraCalibration />
              </Route>
              <Route path={ROUTES.AUTH} exact={true}>
                <AuthPage />
              </Route>
              <Route path={ROUTES.TEST} exact={true}>
                <T />
              </Route>
              <Route path={[...ROUTES.SCHEME, ...ROUTES.STATS]} exact={true}>
                <SchemePage />
              </Route>
              <Route path={ROUTES.ROOT} exact={true}>
                <ProjectsPage />
              </Route>
            </Switch>
          </div>
        </Auth>
      </div>
    </Router>
  )
}

export default App
