import { observer } from 'mobx-react'
import useStore from '../../store'
import React, { useEffect, useRef, useState } from 'react'
import { Cell, Column, ColumnHeaderCell, RenderMode, Table } from '@blueprintjs/table'
import SpinnerComponent from '../../components/Spinner/Spinner'
import { toFull, toTime } from '../../helpers/date'
import { Button, Callout, Card, Checkbox } from '@blueprintjs/core'
import styles from './ProjectScheme.module.pcss'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Legend } from 'recharts'
import { makeCsv } from '../../helpers/csv'

const printMin = secs => {
  let minutes = Math.floor(secs / 60)
  let seconds = secs % 60
  const minus = minutes < 0 || seconds < 0
  if (seconds < 10) {
    seconds = `0${Math.abs(seconds)}`
  }

  return `${minus ? '-' : ''}${Math.abs(minutes)}:${seconds}`
}
const printHours = secs => {
  let hours = Math.floor(secs / 60 / 60)
  let minutes = Math.floor(secs / 60) - hours * 60
  let seconds = secs % 60

  const minus = hours < 0 || minutes < 0 || seconds < 0
  if (minutes < 10) {
    minutes = `0${Math.abs(minutes)}`
  }
  if (seconds < 10) {
    seconds = `0${Math.abs(seconds)}`
  }
  return `${minus ? '-' : ''}${Math.abs(hours)}:${minutes}:${seconds}`
}

const useTableScrollHack = deps => {
  const tableRef = useRef()
  useEffect(() => {
    const t = setTimeout(() => {
      tableRef.current.scrollToRegion({ cols: [0, 0], rows: [1, 1] })
      tableRef.current.scrollToRegion({ cols: [0, 0], rows: [0, 0] })
    }, 10)
    return () => {
      clearTimeout(t)
    }
  }, deps)
  return tableRef
}

const HistoryTable = observer(() => {
  const store = useStore()
  const report = store.projectScheme.currentOIReport
  const rows = report.rows
  const cellRenderer = mapper => rowIndex => <Cell>{mapper(rows[rowIndex])}</Cell>
  const headerRenderer = title => () => <ColumnHeaderCell name={title} />
  const tableRef = useTableScrollHack([report])

  const handleExport = () => {
    const headers = ['Время С', 'Время ПО', '"Время, мин"', 'Область интереса', '"Расстояние, м"']
    const items = rows.map(row => [
      toTime(new Date(row.start_time * 1000)),
      toTime(new Date(row.end_time * 1000)),
      printMin(row.end_time - row.start_time),
      row.out_of_map ? 'Вне карты' : row?.aoi?.name || 'На карте',
      row.out_of_map ? 'N/A' : (parseFloat(row.distance) / 100).toFixed(2),
    ])
    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(makeCsv([headers, ...items])))
    element.setAttribute('download', `${toFull(report.createdAt)}.csv`)
    element.click()
  }
  return (
    <div className={styles.historyTableWrapper}>
      <Table
        ref={tableRef}
        numRows={report.rows.length}
        columnWidths={[100, 100, 100, 300, 200]}
        renderMode={RenderMode.NONE}
        enableColumnResizing={false}
        enableRowResizing={false}
      >
        <Column
          columnHeaderCellRenderer={headerRenderer('Время С')}
          cellRenderer={cellRenderer(row => toTime(new Date(row.start_time * 1000)))}
        />
        <Column
          columnHeaderCellRenderer={headerRenderer('Время ПО')}
          cellRenderer={cellRenderer(row => toTime(new Date(row.end_time * 1000)))}
        />
        <Column
          columnHeaderCellRenderer={headerRenderer('Время, мин')}
          cellRenderer={cellRenderer(row => printMin(row.end_time - row.start_time))}
        />
        <Column
          columnHeaderCellRenderer={headerRenderer('Область интереса')}
          cellRenderer={cellRenderer(row => (row.out_of_map ? 'Вне карты' : row?.aoi?.name || 'На карте'))}
        />
        <Column
          columnHeaderCellRenderer={headerRenderer('Расстояние, м')}
          cellRenderer={cellRenderer(row => (row.out_of_map ? 'N/A' : (parseFloat(row.distance) / 100).toFixed(2)))}
        />
      </Table>
      <Button className={styles.exportButton} onClick={handleExport}>
        Экспорт CSV
      </Button>
    </div>
  )
})

const StatsTable = observer(() => {
  const store = useStore()
  const data = store.projectScheme.statsTableData
  const headerRenderer = title => () => <ColumnHeaderCell name={title} />
  const cellRenderer = mapper => index => <Cell>{mapper(data[index])}</Cell>
  return (
    <Table numRows={data.length} columnWidths={[200, 200, 200]}>
      <Column
        columnHeaderCellRenderer={headerRenderer('Область интереса')}
        cellRenderer={cellRenderer(row => row.name)}
      />
      <Column
        columnHeaderCellRenderer={headerRenderer('Время, мин')}
        cellRenderer={cellRenderer(row => printMin(row.time))}
      />
      <Column
        columnHeaderCellRenderer={headerRenderer('Расстояние, м')}
        cellRenderer={cellRenderer(row => (typeof row.distance === 'number' ? row.distance.toFixed(2) : row.distance))}
      />
    </Table>
  )
})

const Badge = ({ text, title }) => {
  return (
    <Card className={styles.badgeCard}>
      <div className={styles.badgeTitle}>{title}</div>
      <div className={styles.badgeText}>{text}</div>
    </Card>
  )
}
const StatsBadges = observer(() => {
  const store = useStore()
  const data = store.projectScheme.reportStats
  return (
    <Card className={styles.statsBadgesContent}>
      <Badge title={'Расстояние'} text={`${data.distance.toFixed(2)} м`} />
      <Badge title={'Средняя скорость'} text={`${data.avgSpeed.toFixed(2)} км/ч`} />
      <Badge title={'Время на карте'} text={`${printHours(data.totalTime)}`} />
      <Badge title={'Время вне карты'} text={`${printHours(data.outOfMapTime)}`} />
    </Card>
  )
})

// const WorkerChart = observer(() => {
//   const store = useStore()
//
//   return (
//     <ResponsiveContainer width={'100%'} height={300}>
//       <LineChart>
//         <XAxis
//           dataKey="time"
//           type="number"
//           allowDataOverflow={true}
//           domain={['dataMin', 'dataMax']}
//           tickFormatter={tick => toTime(new Date(tick))}
//           scale={'linear'}
//           tick={{ stroke: '#f5f8fa', strokeWidth: 1 }}
//         />
//         <YAxis dataKey="distance" tick={{ stroke: '#f5f8fa', strokeWidth: 1 }} />
//         <CartesianGrid stroke="#f5f8fa" />
//         <Legend payload={workersLegend} />
//         {workersChartData.map(row => {
//           return row.list.map((item, idx) => (
//             <Line
//               key={`line_key_${idx}`}
//               type="monotone"
//               dataKey="distance"
//               data={item}
//               strokeWidth={3}
//               isAnimationActive={false}
//               stroke={row.color}
//               name={row.name}
//             />
//           ))
//         })}
//       </LineChart>
//     </ResponsiveContainer>
//   )
// })

const StatsChart = observer(() => {
  const store = useStore()
  const [showWorkers, setShowWorkers] = useState(false)
  const statsGraphData = store.projectScheme.statsChartData
  const workersChartData = store.projectScheme.allOIChartData
  const workersLegend = workersChartData.map(item => ({
    value: item.name,
    color: `${item.color}88`,
    type: 'line',
    iconType: 'line',
    id: `${item.name}_oi`,
  }))
  const aoi = store.projectScheme.aoi
  const defaultColor = 'rgba(74,102,246,0.87)'
  const selectedOI = store.shared.selectedOI
  const legendPayload = [
    {
      value: `Неразмеченная область (${selectedOI.name})`,
      type: 'line',
      id: 'unknown',
      iconType: 'line',
      color: defaultColor,
    },
    ...aoi.map(aoi => ({
      value: `${aoi.name} (${selectedOI.name})`,
      type: 'line',
      id: `aoi_${aoi.id}`,
      iconType: 'line',
      color: `${aoi.color}88`,
    })),
    ...(showWorkers ? workersLegend : []),
  ]
  return (
    <>
      <Checkbox
        className={styles.showWorkersCheckbox}
        onChange={() => setShowWorkers(!showWorkers)}
        value={showWorkers}
        label={'Показать рабочих'}
      />
      <ResponsiveContainer width={'100%'} height={300}>
        <LineChart>
          <XAxis
            dataKey="time"
            type="number"
            allowDataOverflow={true}
            domain={['dataMin', 'dataMax']}
            tickFormatter={tick => toTime(new Date(tick))}
            scale={'linear'}
            tick={{ stroke: '#f5f8fa', strokeWidth: 1 }}
          />
          <YAxis dataKey="distance" tick={{ stroke: '#f5f8fa', strokeWidth: 1 }} />
          <CartesianGrid stroke="#f5f8fa" />
          <Legend payload={legendPayload} />
          {statsGraphData.list.map((row, idx) => {
            if (row.out_of_map) {
              return null
            }
            return (
              <Line
                key={`line_key_${idx}`}
                type="monotone"
                dataKey="distance"
                data={row.data}
                strokeWidth={3}
                isAnimationActive={false}
                stroke={row.aoi ? `${row.aoi.color}88` : defaultColor}
                name={row.aoi ? row.aoi.name : 'Пустая область'}
              />
            )
          })}
          {showWorkers &&
            workersChartData.map(row => {
              return row.list.map((item, idx) => {
                if (item.out_of_map) {
                  return null
                }
                return (
                  <Line
                    key={`line_workers_key_${idx}`}
                    type="monotone"
                    dataKey="distance"
                    strokeDasharray="3 3"
                    data={item.data}
                    strokeWidth={3}
                    isAnimationActive={false}
                    stroke={`${row.color}88`}
                    name={row.name}
                  />
                )
              })
            })}
        </LineChart>
      </ResponsiveContainer>
    </>
  )
})
const StatsItem = observer(() => {
  const store = useStore()
  const report = store.projectScheme.report
  const selectedOI = store.shared.selectedOI
  if (store.projectScheme.reportsFetchProgress === 'work') {
    return <SpinnerComponent />
  }

  if (store.projectScheme.reportsFetchProgress === 'error') {
    return <Callout intent={'danger'}>Ошибка получения отчета</Callout>
  }

  if (!selectedOI) {
    return <Callout intent={'info'}>Сотрудник не выбран</Callout>
  }

  if (!report) {
    return <Callout intent={'info'}>Отчет не создан. Задайте диапазон времени и нажмите кнопку "Создать отчет"</Callout>
  }

  if (report.empty) {
    return <Callout intent={'info'}>Отчет формируется ⏳</Callout>
  }
  return (
    <div className={styles.statsContent}>
      <div className={styles.statsChart}>
        <StatsChart />
      </div>
      <div className={styles.historyTable}>
        <HistoryTable />
      </div>
      <StatsBadges />
      <div className={styles.statsTable}>
        <StatsTable />
      </div>
    </div>
  )
})

export const StatsContent = observer(() => {
  const store = useStore()
  let loading = false
  if (!store.projects.current) {
    loading = true
  }
  if (store.projectScheme.progress === 'work') {
    loading = true
  }

  if (loading) {
    return <SpinnerComponent />
  }

  const report = store.projectScheme.report

  return (
    <div className={styles.pageContent}>
      <div className={styles.reportControls}>
        <Button
          loading={store.projectScheme.reportCreatingProgress === 'work'}
          onClick={store.projectScheme.createReport}
        >
          {report ? 'Сформировать новый' : 'Создать отчет'}
        </Button>
        {report?.createdAt && <Callout intent={'info'}>Создан: {toFull(report.createdAt)}</Callout>}
        {store.projectScheme.reportCreatingProgress === 'error' ? (
          <Callout intent={'danger'}>Ошибка создания отчета</Callout>
        ) : null}
      </div>
      <StatsItem />
    </div>
  )
})
