import React, { useState } from 'react'
import { Button, Navbar, Icon } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { INFO_SIGN } from '@blueprintjs/icons/lib/esm/generated/iconNames'
import styles from './Nav.module.pcss'
import HelpPopup from 'components/HelpPopup/HelpPopup'
import useStore from 'store'
import { Switch, Route, useParams } from 'react-router-dom'
import { toCameras, toScheme, toCalibration, toStats } from 'pages/routes'
import * as ROUTES from 'pages/routes'
import { observer } from 'mobx-react'
import { Select } from '@blueprintjs/select'
import { MenuItem } from '@blueprintjs/core'

const StatsLink = () => {
  const { projectId, frameId, date } = useParams()
  return (
    <Link to={toStats(projectId, frameId, date)}>
      <Button minimal icon={'timeline-area-chart'}>
        Статистика
      </Button>
    </Link>
  )
}

const CamerasLink = ({ active = false }) => {
  const { projectId, frameId, date } = useParams()
  return (
    <Link to={toCameras(projectId, frameId, date)}>
      <Button minimal icon={'camera'}>
        Камеры
      </Button>
    </Link>
  )
}

const SchemeLink = () => {
  const { projectId, frameId, date } = useParams()
  return (
    <Link to={toScheme(projectId, frameId, date)}>
      <Button minimal icon={'map'}>
        План-схема
      </Button>
    </Link>
  )
}

const CalibrationLink = () => {
  const { projectId, frameId, cameraId, date } = useParams()
  return (
    <Link to={toCalibration(projectId, frameId, cameraId, date)}>
      <Button minimal icon={'zoom-to-fit'}>
        Калибровка камеры
      </Button>
    </Link>
  )
}

const ProjectsLink = () => {
  return (
    <Link to={ROUTES.ROOT}>
      <Button minimal icon={'projects'}>
        Список проектов
      </Button>
    </Link>
  )
}

const Help = () => {
  const [helpOpen, setHelpOpen] = useState(false)
  return (
    <Navbar.Group>
      <Icon icon={INFO_SIGN} onClick={() => setHelpOpen(true)} className={styles.icon} />
      <HelpPopup onClose={() => setHelpOpen(false)} isOpen={helpOpen} />
    </Navbar.Group>
  )
}

const DayItem = (day, { handleClick, modifiers }) => {
  return <MenuItem key={day} active={modifiers.active} disabled={modifiers.disabled} onClick={handleClick} text={day} />
}

const DaySelector = observer(() => {
  const allDatesItem = 'Все даты'
  const store = useStore()

  if (!store.projects.current) {
    return null
  }

  return (
    <Select
      popoverProps={{ minimal: true }}
      activeItem={store.projects.current.selectedDate || allDatesItem}
      filterable={false}
      items={[...store.projects.current.dates]}
      itemRenderer={DayItem}
      onItemSelect={item => {
        if (item === allDatesItem) {
          store.projects.current.setSelectedDate(void 0)
        } else {
          store.projects.current.setSelectedDate(item)
        }
      }}
    >
      <Button>{store.projects.current.selectedDate || 'Все даты'}</Button>
    </Select>
  )
})

const Nav = () => {
  const store = useStore()
  return (
    <Navbar>
      <Switch>
        <Route path={ROUTES.PROJECT} exact={true} strict={true}>
          <Navbar.Group>
            <CamerasLink />
            <SchemeLink />
          </Navbar.Group>
        </Route>
        <Route path={ROUTES.CAMERAS} exact={true} strict={true}>
          <Help />
          <Navbar.Group>
            <SchemeLink />
            {/*<CalibrationLink />*/}
          </Navbar.Group>
          <Navbar.Group>
            <Navbar.Divider />
            <DaySelector />
            <Navbar.Divider />
          </Navbar.Group>
        </Route>
        <Route path={ROUTES.SCHEME} exact={true} strict={true}>
          <Navbar.Group>
            <CamerasLink />
            <StatsLink />
          </Navbar.Group>
          <Navbar.Group>
            <Navbar.Divider />
            <DaySelector />
            <Navbar.Divider />
          </Navbar.Group>
        </Route>
        <Route path={ROUTES.STATS} exact={true} strict={true}>
          <Navbar.Group>
            <CamerasLink />
            <SchemeLink />
          </Navbar.Group>
          <Navbar.Group>
            <Navbar.Divider />
            <DaySelector />
            <Navbar.Divider />
          </Navbar.Group>
        </Route>
        <Route path={ROUTES.CAMERA_CALIBRATION} exact={true} strict={true}>
          <Navbar.Group>
            <CamerasLink />
            <SchemeLink />
            <StatsLink />
          </Navbar.Group>
          <Navbar.Group>
            <Navbar.Divider />
            <DaySelector />
            <Navbar.Divider />
          </Navbar.Group>
        </Route>
      </Switch>
      <Navbar.Group align={'right'}>
        <ProjectsLink />
        <Icon icon={'log-out'} onClick={() => store.session.logOut()} className={styles.icon} />
      </Navbar.Group>
    </Navbar>
  )
}

export default Nav
