import React from 'react'
import { Map, ImageOverlay, GeoJSON, Marker, Circle } from 'react-leaflet'
import L from 'leaflet'
import { FACTORY_X, FACTORY_Y } from '../../constants/bounds'

const T = () => {
  return (
    <Map
      zoomSnap={0}
      minZoom={-2}
      maxZoom={0}
      zoom={-2}
      crs={L.CRS.Simple}
      center={[0, 0]}
      bounds={[
        [0, 0],
        [FACTORY_Y, FACTORY_X],
      ]}
    >
      <Marker position={[10, 10]}>123</Marker>
      <ImageOverlay
        url={'/factory.png'}
        bounds={[
          [0, 0],
          [FACTORY_Y, FACTORY_X],
        ]}
      />
      <Circle fillColor="red" radius={2000} center={[10, 10]} />
    </Map>
  )
}

export default T
