import { observer } from 'mobx-react'
import useStore from 'store'
import SpinnerComponent from 'components/Spinner/Spinner'
import React from 'react'

const AuthorizedOnly = observer(props => {
  const store = useStore()
  if (store.session.progress === 'work') {
    return <SpinnerComponent />
  }
  if (!store.session.authenticated) {
    return null
  }
  return props.children
})

export default AuthorizedOnly
