import { applySnapshot, flow, types, getRoot, destroy } from 'mobx-state-tree'
import axios from 'axios'
import { OI } from 'store/types/oi'
import { AOI } from 'store/types/aoi'

const aoiFilter = aoi => {
  if (!aoi.geometry) {
    return false
  }

  if (!aoi.geometry.length || aoi.geometry.length < 3) {
    return false
  }

  return aoi.geometry.every(coord => ['x', 'y'].every(key => !Number.isNaN(parseInt(coord[key], 10))))
}

export const SharedStore = types
  .model('SharedStore', {
    currentFrame: types.number,
    oi: types.array(OI),
    aoi: types.array(AOI),
    oiProgress: types.string,
    aoiProgress: types.string,
    selectedOI: types.maybeNull(types.reference(OI)),
    progress: types.string,
    autoNextFrame: types.boolean,
  })
  .actions(self => {
    const fetchInitialData = flow(function* initStore(location) {
      try {
        self.progress = 'work'
        self.oiProgress = 'work'
        self.aoiProgress = 'work'
        const [oiRes, aoiRes] = yield Promise.all([
          axios.get('/api/oi/?limit=1000', { withCredentials: true }),
          axios.get('/api/aoi/', { withCredentials: true }),
        ])
        applySnapshot(self.oi, oiRes.data.results)
        applySnapshot(self.aoi, aoiRes.data.results.filter(aoiFilter))
        self.progress = 'success'
        self.oiProgress = 'success'
        self.aoiProgress = 'success'
      } catch (err) {
        console.error('Shared store: Initial data fetch error', err)
        self.progress = 'error'
        self.oiProgress = 'error'
      }
    })

    const setCurrentFrame = frame => {
      self.currentFrame = frame
    }

    const route = flow(function*(location) {
      if (self.progress === 'idle') {
        yield fetchInitialData(location)
      }
    })

    const selectOI = oiId => {
      self.selectedOI = oiId
    }

    const findOIIndex = oi => {
      return self.oi.findIndex(item => item.id === oi.id)
    }

    const nextOI = () => {
      if (self.selectedOI) {
        const i = findOIIndex(self.selectedOI)
        const next = i + 1
        if (i !== -1 && next < self.oi.length) {
          selectOI(self.oi[next].id)
        }
      }
    }

    const prevOI = () => {
      if (self.selectedOI) {
        const i = findOIIndex(self.selectedOI)
        const prev = i - 1
        if (i !== -1 && prev >= 0) {
          selectOI(self.oi[prev].id)
        }
      }
    }

    const toggleAutoNextFrame = () => {
      self.autoNextFrame = !self.autoNextFrame
    }

    const removeAOI = flow(function*(id) {
      yield axios.delete(`/api/aoi/${id}/`, null, { withCredentials: true })
      const item = self.aoi.find(aoi => aoi.id === id)
      if (item) {
        destroy(item)
      }
    })

    const createAOI = flow(function*({ color, name, coords }) {
      const project = getRoot(self).projects.current
      if (!project) {
        return
      }
      try {
        const { data } = yield axios.post(
          `/api/aoi/`,
          { project: project.id, color, name, geometry: coords.map(([x, y]) => ({ x, y })) },
          { withCredentials: true }
        )
        self.aoi.push(data)
      } catch (err) {
        console.log(err)
      }
    })

    return {
      route,
      selectOI,
      nextOI,
      prevOI,
      fetchInitialData,
      setCurrentFrame,
      toggleAutoNextFrame,
      removeAOI,
      createAOI,
    }
  })
