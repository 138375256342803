import React, { useEffect, useCallback } from 'react'
import styles from './Project.module.pcss'
import MarkupCamera from 'components/MarkupCamera/MarkupCamera'
import SpinnerComponent from 'components/Spinner/Spinner'
import EmptyFrame from 'components/EmptyFrame/EmptyFrame'
import { observer } from 'mobx-react'
import { useStore } from 'store'
import Controls from 'components/Controls/Controls'
import { AutoNextFrameProvider } from 'hooks/useAutoNextFrame'
import { useParams } from 'react-router-dom'

const CamerasPage = observer(() => {
  const store = useStore()
  const project = store.projects.current

  const nextFrame = useCallback(
    () => store.projects.current?.setCurrentFrame(store.projects.current?.currentFrame + 1),
    [store]
  )
  const prevFrame = useCallback(
    () => store.projects.current?.setCurrentFrame(store.projects.current?.currentFrame - 1),
    [store]
  )

  useEffect(() => {
    const onKeyUp = ({ key }) => {
      const handles = {
        ArrowUp: () => store.shared.nextOI(),
        ArrowDown: () => store.shared.prevOI(),
        ArrowLeft: prevFrame,
        ArrowRight: nextFrame,
      }

      if (handles[key]) {
        handles[key]()
      }
    }
    document.addEventListener('keyup', onKeyUp)
    return () => document.removeEventListener('keyup', onKeyUp)
  }, [nextFrame, prevFrame, store, store.shared.oi, store.shared.selectedOI])

  if (!project || project.progress !== 'success' || !store.shared.oi.length) {
    return <SpinnerComponent />
  }

  return (
    <AutoNextFrameProvider>
      <div className={styles.root}>
        <CameraPageContent
          rows={project.metadata.row}
          cols={project.metadata.col}
          videos={project.videos}
          selectedOI={store.shared.selectedOI ? store.shared.selectedOI : undefined}
        />
        <Controls />
      </div>
    </AutoNextFrameProvider>
  )
})

const CameraPageContent = observer(({ videos, selectedOI, rows, cols }) => {
  const { projectId, frameId } = useParams()

  const matrix = []
  const maxSizes = new Array(cols).fill(null)

  for (let i = 0; i < rows; i++) {
    matrix[i] = new Array(cols)
    matrix[i].fill(null)
  }

  for (const video of videos) {
    matrix[video.row - 1][video.col - 1] = video
  }

  for (let rowI = 0; rowI < matrix.length; rowI++) {
    const row = matrix[rowI]
    let maxWidth = null
    let maxHeight = null
    for (let colI = 0; colI < row.length; colI++) {
      const video = row[colI]
      if (video) {
        maxWidth = Math.max(video.width, maxWidth)
        maxHeight = Math.max(video.height, maxHeight)
      }
    }

    if (maxWidth !== null && maxHeight !== null) {
      maxSizes[rowI] = {
        width: maxWidth,
        height: maxHeight,
      }
    }
  }

  return (
    <div className={styles.content}>
      {matrix.map((row, rowIndex) => {
        return (
          <div className={styles.row} style={{ gridTemplateColumns: `repeat(${row.length}, 1fr)` }} key={rowIndex}>
            {row.map((video, itemIndex) => {
              if (!video || !video.frame) {
                return (
                  <div className={styles.cell} key={itemIndex}>
                    <EmptyFrame key={itemIndex} />
                  </div>
                )
              }

              return (
                <div className={styles.cell} key={itemIndex}>
                  <MarkupCamera video={video} selectedOI={selectedOI} />
                  <div className={styles.frameInfo}>
                    {video.currentFrameIndex}/{video.frame.locations.length}
                    {/*
                    <Link to={toCalibration(projectId, frameId, video.id)}>
                      <Icon icon={'zoom-to-fit'} title={'Калибровка камеры'} />
                    </Link>
                    */}
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
})

export default CamerasPage
