import moment from 'moment'

export function momentFormatter(format) {
  return {
    formatDate: date => moment(date).format(format),
    parseDate: str => moment(str, format).toDate(),
    placeholder: `${format} (moment)`,
  }
}

export const FORMATS = {
  SHORT: momentFormatter('DD.MM.YYYY'),
  SHORT_DASHED: momentFormatter('YYYY-MM-DD'),
  FULL: momentFormatter('DD.MM.YYYY HH:mm:ss'),
  TIME: momentFormatter('HH:mm:ss')
}

export function toShort(date) {
  return FORMATS.SHORT_DASHED.formatDate(date)
}

export function toFull(date) {
  return FORMATS.FULL.formatDate(date)
}

export function toTime(date) {
  return FORMATS.TIME.formatDate(date)
}
