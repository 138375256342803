import React, { useReducer, useEffect, useCallback } from 'react'
import { Stage, Layer, Rect } from 'react-konva'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import useResize from 'hooks/useResize'
import useStore from '../../store'
import styles from './Canvas.module.pcss'
import useAutoNextFrame from 'hooks/useAutoNextFrame'

const RECT_LENGTH = 25

const clamp = (val, min, max) => (val > max ? max : val < min ? min : val)
const mapRange = (value, fromLow, fromHigh, toLow, toHigh) => {
  value = clamp(value, fromLow, fromHigh)
  return ((value - fromLow) * (toHigh - toLow)) / (fromHigh - fromLow) + toLow
}

const initialState = {
  imgLoaded: false,
  naturalWidth: 0,
  naturalHeight: 0,
  startPoint: {
    x: 0,
    y: 0,
  },
  currentPoint: {
    x: 0,
    y: 0,
  },
  dragging: false,
  shapes: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'image': {
      return {
        ...state,
        imgLoaded: false,
      }
    }
    case 'image_load': {
      return {
        ...state,
        imgLoaded: true,
        naturalWidth: action.payload.naturalWidth,
        naturalHeight: action.payload.naturalHeight,
      }
    }
    case 'drag_start': {
      return {
        ...state,
        dragging: true,
        startPoint: action.payload,
        currentPoint: action.payload,
      }
    }
    case 'drag': {
      return {
        ...state,
        currentPoint: action.payload,
      }
    }
    case 'drag_end': {
      return {
        ...state,
        dragging: false,
        startPoint: { x: 0, y: 0 },
        currentPoint: { x: 0, y: 0 },
        shapes: [...state.shapes, action.payload],
      }
    }
    default: {
    }
  }
  return state
}

const getX = (startPoint, currentPoint) => {
  return startPoint.x < currentPoint.x ? startPoint.x : currentPoint.x
}

const getY = (startPoint, currentPoint) => {
  return startPoint.y < currentPoint.y ? startPoint.y : currentPoint.y
}

const getW = (startPoint, currentPoint) => {
  return Math.abs(currentPoint.x - startPoint.x)
}

const getH = (startPoint, currentPoint) => {
  return Math.abs(currentPoint.y - startPoint.y)
}

const colors = ['#2f79ff', '#f89505', '#3ab084', '#fe0112', '#a83299']

const Canvas = observer(({ frame, bg, onDrawEnd, locations, selectedShapeId, selectedColor, onClick, points = [] }) => {
  const store = useStore()
  const [containerProps, bounds] = useResize()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [autoNextFrame] = useAutoNextFrame()

  const nextFrame = useCallback(() => {
    if (autoNextFrame) {
      store.projects.current?.setCurrentFrame(store.projects.current?.currentFrame + 1)
    }
  }, [store, autoNextFrame])

  const handleDragStart = e => {
    onClick &&
      onClick([
        mapRange(e.evt.layerX, 0, bounds.width, 0, state.naturalWidth),
        mapRange(e.evt.layerY, 0, bounds.height, 0, state.naturalHeight),
      ])

    if (typeof selectedShapeId !== 'number') {
      return
    }

    dispatch({
      type: 'drag_start',
      payload: {
        x: e.evt.layerX,
        y: e.evt.layerY,
      },
    })
  }

  const handleDragMove = e => {
    dispatch({
      type: 'drag',
      payload: {
        x: e.evt.layerX,
        y: e.evt.layerY,
      },
    })
  }

  const handleDragEnd = e => {
    if (!state.imgLoaded || !state.dragging) {
      return
    }

    const start = {
      x: mapRange(state.startPoint.x, 0, bounds.width, 0, state.naturalWidth),
      y: mapRange(state.startPoint.y, 0, bounds.height, 0, state.naturalHeight),
    }
    const end = {
      x: mapRange(state.currentPoint.x, 0, bounds.width, 0, state.naturalWidth),
      y: mapRange(state.currentPoint.y, 0, bounds.height, 0, state.naturalHeight),
    }

    const x = Number((start.x < end.x ? start.x : end.x).toFixed(5))
    const y = Number((start.y < end.y ? start.y : end.y).toFixed(5))
    const w = Number(getW(start, end).toFixed(5))
    const h = Number(getH(start, end).toFixed(5))

    const shape = { x, y, w, h }

    if (w !== 0 && h !== 0) {
      onDrawEnd(shape, () => nextFrame())
    }

    dispatch({ type: 'drag_end', payload: shape })
  }

  const handleStageClick = e => {
    const start = {
      x: mapRange(e.evt.layerX - RECT_LENGTH, 0, bounds.width, 0, state.naturalWidth),
      y: mapRange(e.evt.layerY - RECT_LENGTH, 0, bounds.height, 0, state.naturalHeight),
    }
    const end = {
      x: mapRange(e.evt.layerX + RECT_LENGTH, 0, bounds.width, 0, state.naturalWidth),
      y: mapRange(e.evt.layerY + RECT_LENGTH, 0, bounds.height, 0, state.naturalHeight),
    }

    const x = Number((start.x < end.x ? start.x : end.x).toFixed(5))
    const y = Number((start.y < end.y ? start.y : end.y).toFixed(5))
    const w = Number(getW(start, end).toFixed(5))
    const h = Number(getH(start, end).toFixed(5))

    const shape = { x, y, w, h }

    onDrawEnd(shape, () => nextFrame())

    dispatch({ type: 'drag_end', payload: shape })
  }

  const handleRectClick = (e, rect) => {
    e.cancelBubble = true
    const shape = toJS(rect)

    store.shared.selectOI(shape.oi.id)
    frame?.removeLocation(rect.oi)
  }

  useEffect(() => {
    dispatch({ type: 'image' })

    const img = containerProps.ref.current

    const onLoad = e => {
      const target = e.target
      dispatch({
        type: 'image_load',
        payload: {
          naturalHeight: target?.naturalHeight ?? 0,
          naturalWidth: target?.naturalWidth ?? 0,
        },
      })
    }
    if (img) {
      img.addEventListener('load', onLoad)
    }
    return () => {
      if (img) {
        img.removeEventListener('load', onLoad)
      }
    }
  }, [containerProps.ref, bg])

  return (
    <div className={styles.root}>
      <img className={styles.bg} src={bg} {...containerProps} />
      <Stage
        width={bounds.width}
        height={bounds.height}
        className={styles.container}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDragMove}
        onClick={handleStageClick}
      >
        <Layer>
          {state.dragging && (
            <Rect
              x={getX(state.startPoint, state.currentPoint)}
              y={getY(state.startPoint, state.currentPoint)}
              width={getW(state.startPoint, state.currentPoint)}
              height={getH(state.startPoint, state.currentPoint)}
              stroke={selectedColor}
              strokeWidth={2}
              shadowBlur={10}
            />
          )}
          {state.imgLoaded &&
            locations &&
            locations.map((shape, i) => {
              return (
                <Rect
                  key={`location_${i}`}
                  width={mapRange(shape.w, 0, state.naturalWidth, 0, bounds.width)}
                  height={mapRange(shape.h, 0, state.naturalHeight, 0, bounds.height)}
                  x={mapRange(shape.x, 0, state.naturalWidth, 0, bounds.width)}
                  y={mapRange(shape.y, 0, state.naturalHeight, 0, bounds.height)}
                  stroke={shape.color}
                  strokeWidth={2}
                  shadowBlur={10}
                  onClick={e => handleRectClick(e, shape)}
                />
              )
            })}
          {state.imgLoaded &&
            points.map(([x, y], i) => {
              return (
                <Rect
                  key={`location_${i}`}
                  width={2}
                  height={2}
                  x={mapRange(x, 0, state.naturalWidth, 0, bounds.width)}
                  y={mapRange(y, 0, state.naturalHeight, 0, bounds.height)}
                  stroke={colors[i]}
                  strokeWidth={1}
                />
              )
            })}
        </Layer>
      </Stage>
    </div>
  )
})

export default Canvas
