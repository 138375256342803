import { types } from 'mobx-state-tree'

export const OI = types.model('OI', {
  id: types.identifierNumber,
  name: types.string,
  img1: types.maybeNull(types.string),
  img2: types.maybeNull(types.string),
  img3: types.maybeNull(types.string),
  img4: types.maybeNull(types.string),
  color: types.string,
  created: types.string,
  updated: types.string,
  project: types.maybeNull(types.number),
})
