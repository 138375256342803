import axios from 'axios'
import { applySnapshot, destroy, flow, types } from 'mobx-state-tree'
import { FrameLocation } from 'store/types/frameLocation'
import { Video } from 'store/types/video'
import { Project } from 'store/types/project'

const FRAMES_DELAY = 1000 // 1 second
const delay = async ms => new Promise(resolve => setTimeout(resolve, ms))

async function fetchFrameLocations(frameId) {
  const res = await axios.get(`/api/frame-location/?limit=100&frame=${frameId}`, {
    withCredentials: true,
  })
  return res.data.results
}

export const Frame = types
  .model('Frame', {
    id: types.number,
    name: types.string,
    img_file: types.string,
    created: types.string,
    updated: types.string,
    video: types.reference(types.late(() => Video)),
    locations: types.array(types.late(() => FrameLocation)),
    project: types.reference(types.late(() => Project)),
    index: types.number,
  })
  .views(self => ({
    created() {
      return new Date(self.created)
    },
    updated() {
      return new Date(self.updated)
    },
  }))
  .actions(self => {
    const afterAttach = flow(function* frameAfterCreate() {
      const locations = yield fetchFrameLocations(self.id)
      applySnapshot(self.locations, locations)
    })
    const newLocation = flow(function* newLocation(shape, oi, callback) {
      const newData = {
        ...shape,
        frame: self.id,
        oi: oi.id,
      }
      const existedLocation = self.locations.find(location => location.oi.id === oi.id)

      if (existedLocation) {
        existedLocation.update(newData)
        const res = yield axios.put(`/api/frame-location/${existedLocation.id}/`, newData, { withCredentials: true })
        existedLocation.update({
          ...res.data,
          x: parseFloat(res.data.x),
          y: parseFloat(res.data.y),
          w: parseFloat(res.data.w),
          h: parseFloat(res.data.h),
        })
      } else {
        const res = yield axios.post(`/api/frame-location/`, newData, { withCredentials: true })
        self.locations.push(res.data)
      }

      if (callback) {
        yield delay(FRAMES_DELAY)
        callback()
      }
    })
    const removeLocation = flow(function*(oi, callback) {
      const existedLocation = self.locations.find(location => location.oi.id === oi.id)
      if (existedLocation) {
        yield axios.delete(`/api/frame-location/${existedLocation.id}`)
        destroy(existedLocation)
        if (callback) {
          yield delay(FRAMES_DELAY)
          callback()
        }
      }
    })
    return {
      afterAttach,
      newLocation,
      removeLocation,
    }
  })
