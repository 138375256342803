import { flow, getParent, types, cast } from 'mobx-state-tree'
import axios from 'axios'
import { reaction } from 'mobx'
import { Frame } from './frame'
import { toShort } from 'helpers/date'

export const Video = types
  .model('Video', {
    id: types.identifierNumber,
    name: types.string,
    video_file: types.string,
    scale: types.string,
    roi_top_left_x: types.number,
    roi_top_left_y: types.number,
    roi_bottom_right_x: types.number,
    roi_bottom_right_y: types.number,
    camera_top_left_x: types.number,
    camera_top_left_y: types.number,
    camera_bottom_right_x: types.number,
    camera_bottom_right_y: types.number,
    start: types.string,
    end: types.string,
    fps: types.string,
    created: types.string,
    updated: types.string,
    row_num: types.number,
    col_num: types.number,
    //project: types.late(() => types.reference(Project)),
    //frames: types.array(Frame),
    _cache: types.map(Frame),
  })
  .views(self => ({
    get row() {
      return self.row_num ?? 0
    },
    get col() {
      return self.col_num ?? 0
    },
    get width() {
      return self.roi_bottom_right_x
    },
    get height() {
      return self.roi_bottom_right_y
    },
    get startDate() {
      return new Date(self.start)
    },
    get endDate() {
      return new Date(self.end)
    },
    get baseVideo() {
      const project = getParent(self, 2)
      return [...project._videos].sort((a, b) => {
        const aTime = new Date(a.start).getTime()
        const bTime = new Date(b.start).getTime()
        return aTime > bTime ? -1 : aTime === bTime ? 0 : 1
      })[0]
    },
    get offset() {
      let offset = 0
      if (self.baseVideo) {
        const baseTime = self.baseVideo.startDate.getTime()
        const thisTime = this.startDate.getTime()
        offset = Math.ceil((baseTime - thisTime) / 3000)
      }
      return offset
    },
    get currentFrameIndex() {
      const project = getParent(self, 2)
      return project.currentFrame + this.offset + 1
    },
    get totalFrames() {
      return 0 // self.frames.length
    },
    get frame() {
      return self._cache.get(String(this.currentFrameIndex)) ?? null
    },
    get day() {
      return toShort(self.start)
    },
  }))
  .actions(self => {
    return {
      init() {
        this.fetchFrame(self.currentFrameIndex - 1)
        this.fetchFrame(self.currentFrameIndex)
        this.fetchFrame(self.currentFrameIndex + 1)
      },
      fetchFrame: flow(function*(frameIndex) {
        if (self._cache.has(String(frameIndex))) {
          return
        }
        const res = yield axios.get(`/api/frame/?video=${self.id}&order_num=${frameIndex}`, { withCredentials: true })
        const frame =
          res.data.results.map(v => ({
            id: v.id,
            name: v.name,
            img_file: v.img_file,
            created: v.created,
            updated: v.updated,
            video: v.video,
            project: v.project_id,
            index: frameIndex,
          }))[0] || null

        if (frame) {
          self._cache.set(String(frameIndex), cast(frame))
        }
      }),
      afterAttach() {
        reaction(
          () => self.currentFrameIndex,
          () => this.init()
        )
        this.init()
      },
    }
  })
