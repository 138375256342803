import React, { useState } from 'react'
import { observer } from 'mobx-react'
import axios from 'axios'
import SpinnerComponent from 'components/Spinner/Spinner'
import styles from './Projects.module.pcss'
import { Card } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

const PROGRESS = {
  IDLE: 'IDLE',
  WORK: 'WORK',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

const ProjectsPage = observer(() => {
  const [progress, setProgress] = useState(PROGRESS.IDLE)
  const [error, setError] = useState(undefined)
  const [projects, setProject] = useState([])

  React.useEffect(function() {
    setProgress(PROGRESS.WORK)

    axios
      .get('/api/project/', { withCredentials: true })
      .then(({ data }) => {
        setProject(data.results)
        setProgress(PROGRESS.SUCCESS)
      })
      .catch(e => {
        setProgress(PROGRESS.ERROR)
        setError('Failed to fetch project list')
      })
  }, [])

  if (progress === PROGRESS.WORK) {
    return (
      <div className={styles.loader}>
        <SpinnerComponent />
      </div>
    )
  }

  if (progress === PROGRESS.ERROR) {
    return <div className={styles.error}>{error}</div>
  }

  return (
    <div className={styles.root}>
      {projects.length ? (
        projects.map((project, idx) => {
          return (
            <Link to={`/project/${project.id}/cameras`} className={styles.itemRoot} key={idx}>
              <Card className={styles.card}>{project.name}</Card>
            </Link>
          )
        })
      ) : (
        <div>Empty projects list :(</div>
      )}
    </div>
  )
})

export default ProjectsPage
