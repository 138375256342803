import { types, flow, applySnapshot, getRoot } from 'mobx-state-tree'
import { Project, ProjectsListItem } from 'store/types/project'
import axios from 'axios'
import { matchPath } from 'react-router'
import { CAMERA_CALIBRATION, PROJECT, CAMERAS, SCHEME, STATS } from 'pages/routes'

const match = pathname => {
  return matchPath(pathname, {
    path: [PROJECT, ...CAMERAS, ...CAMERA_CALIBRATION, ...SCHEME, ...STATS],
    strict: true,
    exact: true,
  })?.params
}

export const ProjectsStore = types
  .model('ProjectsStore', {
    current: types.maybeNull(
      types.reference(Project, {
        get(identifier, parent) {
          parent.loadProject(Number(identifier))
          return parent._cache.get(identifier)
        },
        set(value) {
          return value.id
        },
      })
    ),
    _cache: types.map(Project),
    list: types.array(ProjectsListItem),
    total: types.number,
    progress: types.string,
    error: types.string,
  })
  /*.views(self => ({
  }))*/
  .actions(self => {
    // eslint-disable-next-line require-yield
    const loadProject = flow(function*(id) {
      const root = getRoot(self)
      const params = match(root.router.location.pathname)

      if (!self._cache.has(String(id))) {
        self._cache.put({
          id,
          metadata: id,
          progress: 'work',
          currentFrame: Number(params?.frameId) || 0,
          selectedVideo: Number(params?.cameraId) || undefined,
          selectedDate: params.date,
          _videos: [],
        })
      }
    })

    const setCurrent = id => {
      self.current = id
    }

    const route = flow(function*(location) {
      const params = match(location.pathname)
      if (params) {
        self.current = params.projectId
        if (self.current.progress === 'success') {
          self.current.currentFrame = Number(params.frameId) || 0
          self.current.selectedVideo = Number(params.cameraId) || undefined
        }
      }
      if (self.progress !== 'idle') {
        return
      }

      self.progress = 'work'
      try {
        const res = yield axios.get('/api/project/', { withCredentials: true })
        self.list = res.data.results
        self.total = res.data.count
        self.progress = 'success'
        self.error = ''
      } catch (err) {
        console.error(err)
        applySnapshot(self, {
          total: 0,
          progress: 'error',
          list: [],
          error: 'Failed to fetch project list (see console)',
        })
      }
    })
    return {
      route,
      loadProject,
      setCurrent,
    }
  })
