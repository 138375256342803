import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import useStore from 'store'
import useKeyPress from 'hooks/useKeyPress'
import styles from './OISelect.module.pcss'

const OISelectItem = React.memo(({ position, id, name, color, isActive, onClick }) => {
  const onItemClick = () => onClick(id)

  useKeyPress(`${position + 1}`, onItemClick)

  return (
    <button
      className={clsx(styles.btn, { [styles.btnActive]: isActive })}
      style={{ border: `1px solid ${color}` }}
      onClick={onItemClick}
    >
      {name.replace('Сотрудник ', '')}
    </button>
  )
})

const OISelectList = React.memo(({ currentId, items, onClick }) => {
  return items.map((item, position) => {
    return (
      <OISelectItem
        key={item.id}
        position={position}
        id={item.id}
        name={item.name}
        color={item.color}
        isActive={currentId === item.id}
        onClick={onClick}
      />
    )
  })
})

const OISelectInner = observer(() => {
  const store = useStore()
  const projectId = toJS(store.projects.current.id)
  const current = toJS(store.shared.selectedOI)
  const oi = toJS(store.shared.oi)

  const items = React.useMemo(() => {
    return oi.filter(item => item.project === projectId)
  }, [projectId, oi])

  const onEmptyClick = React.useCallback(() => store.shared.selectOI(undefined), [store])
  const onClick = React.useCallback(id => store.shared.selectOI(id), [store])

  useKeyPress('0', onEmptyClick)

  return (
    <>
      <span style={{ marginRight: '10px' }}>Сотрудник: </span>
      <button
        onClick={() => onClick(undefined)}
        className={clsx(styles.btn, {
          [styles.btnActive]: !current,
        })}
      >
        Не выбран
      </button>
      <OISelectList items={items} currentId={current?.id} onClick={onClick} />
    </>
  )
})

export const OISelect = observer(() => {
  const store = useStore()

  if (!store.shared.oi.length) {
    return null
  }

  return <OISelectInner />
})
