import { Select } from '@blueprintjs/select'
import { MenuItem } from '@blueprintjs/core'
import React from 'react'

export const VideoSelect = Select.ofType()
export const VideoItemRenderer = (oi, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={oi.id}
      onClick={handleClick}
      text={oi.name}
    />
  )
}
