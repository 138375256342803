import { types, flow } from 'mobx-state-tree'
import axios from 'axios'

export const AOI = types
  .model('AOI', {
    id: types.identifierNumber,
    name: types.string,
    color: types.string,
    geometry: types.array(
      types.model({
        x: types.number,
        y: types.number,
      })
    ),
    created: types.string,
    updated: types.string,
    project: types.number,
  })
  .actions(self => {
    const setColor = flow(function*(color) {
      try {
        yield axios.patch(`/api/aoi/${self.id}/`, { color }, { withCredentials: true })
        self.color = color
      } catch (err) {
        console.error(err)
      }
    })

    const update = flow(function*({ name, color, coords }) {
      try {
        const geometry = coords ? coords.map(([x, y]) => ({ x, y })) : undefined
        yield axios.patch(`/api/aoi/${self.id}/`, { geometry, name, color }, { withCredentials: true })
        if (geometry) {
          self.geometry = geometry
        }
        if (name) {
          self.name = name
        }
        if (color) {
          self.color = color
        }
      } catch (err) {
        console.error(err)
      }
    })

    return {
      setColor,
      update,
    }
  })
